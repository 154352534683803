





import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'DatavPage',
})
export default class DatavPage extends Vue {
  static componentName = 'DatavPage'

  // 大屏属性
  @Prop(String) readonly size?: number[]
  @Prop({ default: '#fff' }) readonly background?: string
  @Prop(String) readonly url?: string

  get extraStyles() {
    let styles: Record<string, string> = {}
    styles.width = this.size ? this.size[0] + 'px' : '1920px'
    styles.height = this.size ? this.size[1] + 'px' : '1080px'
    if (this.background) {
      styles.background = this.background
    }
    if (this.url) {
      styles.background = `url(${this.url})`
    }
    return styles
  }
}
