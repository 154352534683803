














import { Component, Mixins } from 'vue-property-decorator'
import LinkMixin from '@/amui/mixins/linkMixin'

@Component({
  name: 'AmFeLinkContainer',
})
export default class AmFeLinkContainer extends Mixins(LinkMixin) {
  static componentName = 'AmFeLinkContainer'
}
