
import { Component, Prop, Mixins } from 'vue-property-decorator'
import AmBizSelectField from './AmBizSelectField.vue'
@Component({
  name: 'AmTeacherField',
})
export default class AmTeacherField extends Mixins(AmBizSelectField) {
  static componentName = 'AmTeacherField'
  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get(
        'apaas_amui_userfield_placeholder',
        '请选择用户'
      )
    },
  })
  placeholder!: string

  get label_i18n() {
    return this.label === '选择讲师'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_teacherfield_label',
          '选择讲师'
        )
      : this.label
  }
}
