














import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'AmDivider',
})
export default class AmDivider extends Vue {
  static componentName = 'AmDivider'

  @Prop({ default: 'horizontal' }) readonly direction!: string
  @Prop({ default: 'center' }) readonly contentPosition!: string
  @Prop({ default: '#E9E9E9' }) readonly lineColor!: string
  @Prop({ default: 'light' }) readonly themeMode!: string
}
