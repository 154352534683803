import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import { MenuItem } from './index'
import '../assets/css/AmFeMenu.scss'
import { lowcode } from '@/amui'

@Component({
  name: 'AmFeMenu',
})
export default class AmFeMenu extends Vue {
  static componentName = 'AmFeMenu'
  @Prop({ default: '' }) readonly title!: string
  @Prop({ default: () => [] }) readonly data!: MenuItem[]
  @Prop({ default: 'vertical' }) readonly mode!: string
  @Prop({ default: false }) readonly uniqueOpened!: boolean
  @Prop({ default: 'hover' }) readonly menuTrigger!: string
  @Prop({ default: '' }) readonly defaultActive!: string
  @Prop({ default: '184px' }) readonly menuWidth!: string
  @Prop({ default: true }) readonly showCollapse!: boolean
  @Prop({ default: true }) readonly shadow!: boolean

  public isCollapse = false
  public isHover = false
  public iconSize = 16
  private checkCustomMethod: boolean = true

  @Emit('select')
  handleSelect(index: number, path: Array<string>) {
    if (this.checkCustomMethod) {
      const data = this.getDataByPath(path)
      if (data && data.method) {
        if (typeof data.method == 'function') {
          data.method(data)
        } else {
          // 通过am api打开相应的链接
          lowcode.navigate(data.method)
        }
      }
    }
  }

  @Emit('open')
  handleOpen() {}

  @Emit('close')
  handleClose() {}

  @Watch('isCollapse')
  handleCollapseChange(newval, oldval) {
    this.$emit('collapse', newval)
  }

  triggerCollapse() {
    this.isCollapse = !this.isCollapse
    this.isHover = this.isCollapse
  }

  getDataByPath(arr: Array<string>) {
    let res = arr.concat()
    let root: Array<any> = this.data || []
    let current: Record<string, any> | null = null
    let paths: any[] = []
    while (true) {
      let path = res.shift()
      paths.push(path)
      let find = root.find(item => {
        return item.index == path
      })
      if (!find) {
        break
      } else {
        let childs = find.children ?? []
        let item = []
        for (let i = 0; i < childs.length; i++) {
          if (childs[i].isGroup) {
            item = item.concat(childs[i].children ?? [])
          } else {
            item = item.concat(childs[i] ?? [])
          }
        }
        root = item
        current = find
      }
    }
    return current
  }
  leftEnter() {
    if (this.isHover) this.isCollapse = false
  }

  leftLeave() {
    if (this.isHover) this.isCollapse = true
  }

  render(h) {
    const {
      title,
      data,
      defaultActive,
      mode,
      uniqueOpened,
      menuWidth,
      menuTrigger,
      showCollapse,
      isCollapse,
      isHover,
      shadow,
      handleSelect,
      handleOpen,
      handleClose,
      triggerCollapse,
      leftEnter,
      leftLeave,
    } = this

    const renderMenuItem = (data: MenuItem[], subIndex?: string) => {
      return data.map((item, index) => {
        let iconType = item.iconType || 'default'
        let iconName = item.iconName || ''
        if (!item.iconName && !item.iconType) {
          if (item.icon) {
            iconType = 'default'
            iconName = item.icon.startsWith('yxt-icon-')
              ? item.icon.split('yxt-icon-')[1]
              : item.icon
          }
          if (item.svgIcon) {
            iconType = 'svg'
            iconName = item.svgIcon
          }
        }
        if (item?.groups?.length || (item?.isGroup && item?.children?.length)) {
          const list: MenuItem[] = item?.children || item?.groups || []
          return (
            <yxtf-menu-item-group title={item.title}>
              {renderMenuItem(
                list,
                `${subIndex ? `${subIndex}-` : subIndex}${index++}`
              )}
            </yxtf-menu-item-group>
          )
        } else if (item?.children?.length) {
          return (
            <yxtf-submenu
              index={item.index || subIndex}
              disabled={item.disabled}
            >
              <template slot="title">
                {iconName ? (
                  <am-icon
                    class="lc-am-menu-icon"
                    icon-type={iconType}
                    name={iconName}
                    size={this.iconSize}
                  ></am-icon>
                ) : null}
                <span>{item.title}</span>
              </template>
              {renderMenuItem(
                item.children,
                `${subIndex ? `${subIndex}-` : subIndex}${index++}`
              )}
            </yxtf-submenu>
          )
        } else {
          return (
            <yxtf-menu-item
              index={item.index || subIndex}
              disabled={item.disabled}
            >
              {iconName ? (
                <am-fe-icon
                  class="lc-am-menu-icon"
                  icon-type={iconType}
                  name={iconName}
                  size={this.iconSize}
                ></am-fe-icon>
              ) : null}
              <span slot="title">{item.title}</span>
            </yxtf-menu-item>
          )
        }
      })
    }

    return (
      <div
        style={{
          width: isHover ? '64px' : menuWidth,
          paddingBottom: mode === 'vertical' && showCollapse ? '48px' : '0',
        }}
        class={[
          'lc-am-menu-transition',
          { 'lc-am-menu_vertical': mode === 'vertical' },
          shadow ? 'lc-am-menu_box_shadow' : 'lc-am-menu_box_border',
        ]}
      >
        <div
          style={{
            width: isCollapse ? '64px' : menuWidth,
            paddingBottom: mode === 'vertical' && showCollapse ? '48px' : '0',
          }}
          class={['lc-am-menu_box', 'lc-am-menu-transition']}
        >
          {mode === 'vertical' &&
            (title ? (
              <div class="lc-am-menu_title">{title}</div>
            ) : (
              !isCollapse && (
                <div class="lc-am-menu_slot_header">{this.$slots.header}</div>
              )
            ))}
          <yxtf-menu
            default-active={defaultActive}
            mode={mode}
            collapse={isCollapse}
            unique-opened={uniqueOpened}
            menu-trigger={menuTrigger}
            collapse-transition={false}
            onselect={handleSelect}
            onopen={handleOpen}
            nativeOn={{ mouseenter: leftEnter, mouseleave: leftLeave }}
            onclose={handleClose}
          >
            {renderMenuItem(data)}
          </yxtf-menu>
          {mode === 'vertical' && showCollapse && (
            <div class="lc-am-menu_aside-bottom">
              <yxtf-svg
                nativeOn={{ click: triggerCollapse }}
                icon-class="collapse"
                remote-url={lowcode.utils.url2cdn('assets/e57f23c2/f7b885e3')}
                width="18px"
                height="18px"
                class
                style={{ transform: isCollapse ? 'rotate(180deg)' : '' }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
