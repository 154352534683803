






import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'Mark',
})
export default class Mark extends Vue {
  static componentName = 'Mark'
  @Prop(String) readonly width?: string

  get style() {
    return { width: this.width ? this.width : '100%' }
  }
}
