import { Vue, Component, Prop } from 'vue-property-decorator'
import { lowcode } from '..'
@Component({
  name: 'AmImageMixin',
})
export default class AmImageMixin extends Vue {
  @Prop({
    default: () => {
      return lowcode.utils.url2cdn('amupload/static/component_img.png')
    },
  })
  src!: string
  @Prop() fit?: string
  @Prop({ default: '350' }) width!: string
  @Prop({ default: false }) autoWidth!: boolean
  @Prop({ default: '200' }) height!: string
  @Prop({ default: false }) autoHeight!: boolean
  @Prop() borderRadius?: string
  @Prop() title?: string
  @Prop({ default: 'Image 404' }) alt!: string
  @Prop({ default: false }) lazy!: boolean
  @Prop() dataId!: string
  @Prop({ default: [] }) previewSrcList!: string[]

  get style() {
    return {
      width: this.autoWidth
        ? 'auto'
        : this.width
        ? `${this.width}px`
        : undefined,
      height: this.autoHeight
        ? 'auto'
        : this.height
        ? `${this.height}px`
        : undefined,
      borderRadius: this.borderRadius,
    }
  }
}
