






import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'AmFePopupContainerItem',
})
export default class AmFePopupContainerItem extends Vue {
  static componentName = 'AmFePopupContainerItem'
}
