







































import { Component, Prop, Mixins } from 'vue-property-decorator'
import formMixin from '@/amui-fe/mixins/formMixin'
import amSelectFieldMixin from '@/amui-fe/mixins/amSelectFieldMixin'

@Component({
  name: 'AmFeSelectField',
})
export default class AmFeSelectField extends Mixins(
  formMixin,
  amSelectFieldMixin
) {
  static componentName = 'AmFeSelectField'

  // @Prop(String) readonly value!: string
  @Prop({ default: '请选择' }) readonly placeholder!: string
  @Prop({ default: false }) readonly remote!: boolean
  @Prop({ default: false }) readonly loading!: boolean
  @Prop(Function) readonly remoteMethod?: () => any
  @Prop({ default: false }) readonly clearable!: boolean
  @Prop() valueKey!: string

  get label_i18n() {
    return this.label === '单选下拉框'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_selectfield_label',
          '单选下拉框'
        )
      : this.label
  }
}
