










import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'Container',
})
export default class Container extends Vue {
  static componentName = 'Container'
  @Prop() width?: string
  @Prop() height?: string

  get computedWidth() {
    return typeof this.width === 'number' ? this.width + 'px' : this.width
  }

  get computedHeight() {
    return typeof this.height === 'number' ? this.height + 'px' : this.height
  }
}
