

























import { Component, Mixins } from 'vue-property-decorator'
import FormMixin from '@/amui/mixins/formMixin'
@Component({
  name: 'AmFormElementContainer',
})
export default class AmFormElementContainer extends Mixins(FormMixin) {
  static componentName = 'AmFormElementContainer'

  get label_i18n() {
    return this.label === '表单项'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_formelementcontainer_label',
          '表单项'
        )
      : this.label
  }
}
