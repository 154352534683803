


































import { Component, Mixins } from 'vue-property-decorator'
import ButtonMixin from '@/amui/mixins/buttonMixin'

@Component({
  name: 'AmButton',
})
export default class AmButton extends Mixins(ButtonMixin) {
  static componentName = 'AmButton'
}
