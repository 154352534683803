


















































import { Component, Prop, Mixins } from 'vue-property-decorator'
import formMixin from '@/amui/mixins/formMixin'
import amSelectFieldMixin from '@/amui/mixins/amSelectFieldMixin'

@Component({
  name: 'AmMultiSelectField',
})
export default class AmMultiSelectField extends Mixins(
  formMixin,
  amSelectFieldMixin
) {
  static componentName = 'AmMultiSelectField'

  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get(
        'apaas_amui_formfieldselect_placeholder',
        '请选择'
      )
    },
  })
  readonly placeholder!: string
  @Prop({ default: 90 }) readonly multipleLimit!: number
  @Prop({ default: false }) readonly collapseTags!: boolean

  get label_i18n() {
    return this.label === '多选下拉框'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_multiselectfield_label',
          '多选下拉框'
        )
      : this.label
  }
}
