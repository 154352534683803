
import { Component, Prop, Mixins } from 'vue-property-decorator'
import Group from '@/amui/components/Group.vue'

@Component({
  name: 'AmGroup',
})
export default class AmGroup extends Mixins(Group) {
  static componentName = 'AmGroup'

  @Prop({ default: 'yxt-tooltip' }) tooltipName!: string
}
