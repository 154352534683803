import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'AmSelectFieldMixin',
})
export default class AmSelectFieldMixin extends Vue {
  @Prop({ default: false }) readonly filterable!: boolean
  @Prop({ type: Array, default: () => [] }) readonly options!: any[]
  @Prop(Object) readonly labelValueMapInfo?: { label: string; value: string }

  get mappedLabelField() {
    return this.labelValueMapInfo?.label || 'label'
  }

  get mappedValueField() {
    return this.labelValueMapInfo?.value || 'value'
  }
}
