
























import { Component, Mixins } from 'vue-property-decorator'
import amTableColumnTemplateMixin from '../mixins/amTableColumnTemplateMixin'

@Component({
  name: 'AmTableColumnTemplate',
})
export default class extends Mixins(amTableColumnTemplateMixin) {
  static componentName = 'AmTableColumnTemplate'
}
