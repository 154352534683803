







































































































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import FormMixin from '@/amui-fe/mixins/formMixin'
@Component({
  name: 'AmFeTimePickerField',
})
export default class AmFeTimePickerField extends Mixins(FormMixin) {
  static componentName = 'AmFeTimePickerField'

  @Prop({ default: 1 }) readonly type!: number
  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get(
        'apaas_amui_timepickerfield_placeholder',
        '选择时间'
      )
    },
  })
  readonly placeholder!: string
  @Prop({ default: 'HH:mm:ss' }) readonly format!: string
  @Prop({ default: true }) readonly clearable!: boolean
  @Prop({ default: false }) readonly isRange!: boolean
  @Prop() readonly pickerOptions!: Record<string, any>
  @Prop({ default: 'HH:mm:ss' }) valueFormat!: string

  start = ''
  end = ''

  get dateValueFormat() {
    if (this.valueFormat == 'HH:mm:ss') {
      return this.type == 1 ? this.pickerOptions.format : 'HH:mm'
    } else {
      return this.valueFormat
    }
  }

  get newListeners() {
    if (this.type == 2 && this.isRange) {
      return Object.assign(this.$listeners, {
        change: this.setVal,
      })
    }
    return this.$listeners
  }

  @Watch('value', { immediate: true })
  valueChange(v) {
    if (this.type == 2 && this.isRange && Array.isArray(this.value)) {
      this.start = this.value[0]
      this.end = this.value[1]
    } else {
      this.start = ''
      this.end = ''
    }
  }

  setVal() {
    const value = [this.start, this.end]
    this.change(value)
  }

  get label_i18n() {
    return this.label === '时间'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_timepickerfield_label',
          '时间'
        )
      : this.label
  }
}
