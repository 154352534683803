























































import { Mixins, Component, Prop } from 'vue-property-decorator'
import ModalMixin from '@/amui/mixins/modalMixin'

@Component({
  name: 'AmDrawer',
})
export default class extends Mixins(ModalMixin) {
  static componentName = 'AmDrawer'

  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get('apaas_amui_drawer_title', '抽屉')
    },
  })
  readonly title!: string
  @Prop({ default: 'rtl' }) readonly direction!: string

  handleBeforeClose(done) {
    done(false)
  }
}
