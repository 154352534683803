
















































import { Component, Prop, Mixins } from 'vue-property-decorator'
import FormMixin from '@/amui/mixins/formMixin'

@Component({
  name: 'AmCheckboxFeild',
})
export default class AmRadioFeild extends Mixins(FormMixin) {
  static componentName = 'AmCheckboxFeild'

  @Prop(String) itemDirection!: string
  @Prop(String) shape!: string
  @Prop(String) readonly border!: boolean
  @Prop({ default: () => [] }) options!: Array<any>
}
