






import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'AmSlotTableColumnEdit',
})
export default class extends Vue {
  static componentName = 'AmSlotTableColumnEdit'
}
