
import { Component, Prop, Mixins } from 'vue-property-decorator'
import Group from '@/amui/components/Group.vue'

@Component({
  name: 'AmFeGroup',
})
export default class AmFeGroup extends Mixins(Group) {
  static componentName = 'AmFeGroup'

  @Prop({ default: 'yxtf-tooltip' }) tooltipName!: string
}
