






import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'AmFeSlotTableColumnEdit',
})
export default class extends Vue {
  static componentName = 'AmFeSlotTableColumnEdit'
}
