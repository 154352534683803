






















import { Component, Mixins } from 'vue-property-decorator'
import IconMixin from '@/amui/mixins/iconMixin'

@Component({
  name: 'AmIcon',
})
export default class SvgIcon extends Mixins(IconMixin) {
  static componentName = 'AmIcon'
}
