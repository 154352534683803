






























































































































































import { Component, Mixins } from 'vue-property-decorator'
import amTableColumnMixin from '../../amui/mixins/amTableColumnMixin'
import AmFeTableColumnTemplate from './AmFeTableColumnTemplate.vue'

@Component({
  components: { AmFeTableColumnTemplate },
  name: 'AmFeTableColumn',
})
export default class extends Mixins(amTableColumnMixin) {
  static componentName = 'AmFeTableColumn'
}
