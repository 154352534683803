
































import { Component, Prop, Mixins } from 'vue-property-decorator'
import FormMixin from '@/amui-fe/mixins/formMixin'
@Component({
  name: 'AmFeDateRangePickerField',
})
export default class AmFeDateRangePickerField extends Mixins(FormMixin) {
  static componentName = 'AmFeDateRangePickerField'

  @Prop({ default: 'daterange' }) readonly type!: string
  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get(
        'apaas_amui_daterangepickerfield_placeholderstart',
        '开始日期'
      )
    },
  })
  readonly startPlaceholder!: string
  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get(
        'apaas_amui_daterangepickerfield_placeholderend',
        '结束日期'
      )
    },
  })
  readonly endPlaceholder!: string
  // 显示在输入框中的格式，默认格式 yyyy-MM-dd HH:mm:ss
  @Prop({ default: 'yyyy-MM-dd' }) readonly format!: string
  // 绑定值的格式，不指定则绑定值为 Date 对象
  @Prop({ default: 'yyyy-MM-dd' }) readonly valueFormat!: string
  @Prop({ default: false }) readonly clearable!: boolean

  get dateFormat() {
    switch (this.type) {
      case 'daterange':
        // 日期区间
        return 'yyyy-MM-dd'
      case 'monthrange':
        // 月区间
        return 'yyyy-MM'
      case 'datetimerange':
        // 日期时间区间
        return 'yyyy-MM-dd HH:mm'
      default:
        return ''
    }
  }
  get dateValueFormat() {
    if (this.valueFormat == 'yyyy-MM-dd') {
      return this.dateFormat
    } else {
      return this.valueFormat
    }
  }

  get label_i18n() {
    return this.label === '日期区间'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_daterangepickerfield_label',
          '日期区间'
        )
      : this.label
  }
}
