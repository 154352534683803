import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'AmSelectFieldMixin',
})
export default class AmSelectFieldMixin extends Vue {
  @Prop({ default: false }) readonly remote!: boolean
  @Prop({ default: false }) readonly loading!: boolean
  @Prop({ default: false }) readonly filterable!: boolean
  @Prop({ default: false }) readonly allowCreate!: boolean
  @Prop({ default: false }) readonly clearable!: boolean
  @Prop({ default: false }) readonly hiddenOption!: boolean
  @Prop({ default: '' }) readonly iconName!: string
  @Prop({ type: Array, default: () => [] }) readonly options!: any[]
  @Prop(String) valueKey?: string
  @Prop(String) propLabel?: string
  @Prop(String) popperClass?: string
  @Prop(Object) readonly labelValueMapInfo?: { label: string; value: string }
  @Prop(Function) readonly remoteMethod?: () => any

  get mappedValueField() {
    return this.labelValueMapInfo?.value || this.valueKey || 'value'
  }
  get mappedLabelField() {
    return this.labelValueMapInfo?.label || this.propLabel || 'label'
  }
}
