




















import { Component, Mixins } from 'vue-property-decorator'
import Mixin from './mixin'

@Component({
  name: 'AmPopupContainer',
})
export default class AmPopupContainer extends Mixins(Mixin) {
  static componentName = 'AmPopupContainer'

  get popoverWidth() {
    return this.width ? this.width.split('px')[0] : this.width
  }
}
