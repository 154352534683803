




















import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  name: 'AmFeStep',
})
export default class AmFeStep extends Vue {
  static componentName = 'AmFeStep'

  @Prop() space?: string | number
  @Prop({ default: 'horizontal' }) direction!: string
  @Prop({ default: 0 }) active!: number
  @Prop({ default: 'process' }) processStatus!: string
  @Prop({ default: 'finish' }) finishStatus!: string
  @Prop({ default: false }) alignCenter!: boolean
  @Prop({ default: false }) simple!: boolean
  @Prop({ default: () => [] }) options!: Array<any>
  // @Prop({ default: 1 }) type!: number

  // get isSimple() {
  //   return this.simple || this.type == 3
  // }
  // get isBasic() {
  //   return this.type == 2
  // }
}
