















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'AmFeExpandSider',
})
export default class AmFeExpandSider extends Vue {
  static componentName = 'AmFeExpandSider'

  @Prop({ default: false }) readonly bordered?: boolean
  @Prop({ default: 'top' }) readonly togglePosition?:
    | 'top'
    | 'center'
    | 'bottom'
  @Prop({ default: '' }) readonly asideWidth?: string
  @Prop({ default: '0px' }) readonly asidePadding?: string
  @Prop({ default: '16px' }) readonly bodyPadding?: string

  get width() {
    if (!this.asideWidth || this.asideWidth === '0px') return 0
    return parseInt(this.asideWidth.replace('px', ''))
  }

  get siderClass() {
    return this.bordered ? 'lc-am-expand-sider-bordered' : ''
  }

  get asideClass() {
    if (!this.togglePosition || this.togglePosition === 'top') return ''
    if (this.togglePosition === 'center') return 'toggle-position-center'
    return 'toggle-position-bottom'
  }

  get asideStyle() {
    return {
      padding: this.asidePadding || void 0,
    }
  }

  get bodyStyle() {
    return {
      padding: this.bodyPadding || void 0,
    }
  }
}
