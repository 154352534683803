
import { Component, Prop, Mixins } from 'vue-property-decorator'
import AmBizSelectField from './AmBizSelectField.vue'
@Component({
  name: 'AmDeptField',
})
export default class AmDeptField extends Mixins(AmBizSelectField) {
  static componentName = 'AmDeptField'

  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get(
        'apaas_amui_deptfield_placeholder',
        '请选择部门'
      )
    },
  })
  placeholder!: string

  get label_i18n() {
    return this.label === '选择部门'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_deptfield_label',
          '选择部门'
        )
      : this.label
  }
}
