














import { Component, Prop, Mixins } from 'vue-property-decorator'
import LinkMixin from '@/amui/mixins/linkMixin'

@Component({
  name: 'AmFeLink',
})
export default class AmFeLink extends Mixins(LinkMixin) {
  static componentName = 'AmFeLink'

  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get(
        'apaas_amui_link_content',
        '这里是一个链接'
      )
    },
  })
  content!: string
  @Prop({ default: false }) ellipsis!: boolean
}
