






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'Layout',
})
export default class Layout extends Vue {
  static componentName = 'Layout'

  @Prop({ default: '16px' }) rowsGap!: string
  @Prop({ default: '16px' }) columnsGap!: string
  @Prop({ default: 12 }) cols!: number
  @Prop({ default: '12:9:3' }) ratio!: string
  @Prop({ default: '' }) rows!: string

  get style() {
    return [
      {
        gap: `${this.rowsGap} ${this.columnsGap}`,
        'grid-template-columns': `repeat(${this.cols}, minmax(0px, 1fr))`,
        'grid-template-rows': this.rows ? this.rows : false,
        'place-items': 'stretch',
        'grid-auto-rows': 'auto',
        'grid-area': 'span 1 / span 1 / auto / auto',
        display: 'grid',
        position: 'relative',
      },
    ]
  }

  get gridList() {
    let list = this.ratio.split(':').map(val => parseInt(val))
    if (list.length < 2 && isNaN(list[0])) list = [12]
    return list
  }
}
