






























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Group',
})
export default class Group extends Vue {
  static componentName = 'Group'

  @Prop({ type: Boolean, default: true }) showHeader!: boolean
  @Prop({ type: Boolean, default: false }) isMargin!: boolean
  @Prop({ type: Boolean, default: true }) isPadding!: boolean
  @Prop({ type: Boolean, default: true }) isBorder!: boolean
  @Prop({ type: Boolean, default: true }) headerLine!: boolean
  @Prop({
    type: String,
    default: () => {
      return (window as any).lowcode.i18n.get('apaas_amui_group_title', '分组')
    },
  })
  title!: string
  @Prop({ type: String, default: '' }) icon!: string
  @Prop({ type: String, default: '' }) tooltip!: string
  @Prop({ type: String, default: '' }) subTitle!: string
  @Prop({ type: String, default: '' }) extra!: string

  get marginClass() {
    return this.isMargin ? 'lc-am-group-margin' : ''
  }

  get paddingClass() {
    return this.isPadding ? 'lc-am-group-content-padding' : ''
  }

  get borderClass() {
    return this.isBorder ? 'lc-am-group-border' : ''
  }

  get dividerClass() {
    return this.headerLine ? 'lc-am-group-header-divider' : ''
  }
}
