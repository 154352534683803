
















































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import FormMixin from '@/amui/mixins/formMixin'
@Component({
  name: 'AmBizSelectKng',
})
export default class AmBizSelectKng extends Mixins(FormMixin) {
  static componentName = 'AmBizSelectKng'
  openPage = false

  //选择形式
  @Prop({ default: 'button' }) selectType!: string
  //按钮文案
  @Prop({ default: '选择课程' }) buttonText!: string
  //按钮类型
  @Prop({ default: 'primary' }) buttonType!: string
  @Prop({ default: 'medium' }) readonly buttonSize!: string

  //选择课程最大数量（不传或传 0 则表示没有最大数量限制）
  @Prop({ default: 10 }) maxNum!: number
  //初始加载选择课程的类型（0-课程、1-文档、2-视频、3-音频、4-微课、5-SCORM、6-HTML、7-压缩包(M1 无压缩包)、8-外链、999-知识类型（不限））
  @Prop() kngSelectType!: number
  //是否排除压缩包类型
  @Prop({ default: true }) isRemoveZip!: boolean
  //是否排除课程包类型课程
  @Prop({ default: false }) excludeFileType!: boolean
  //是否展示知识库目录
  @Prop({ default: true }) IsKngMenu!: boolean
  //是否展示按次购买的课程
  @Prop({ default: true }) IsBuyCourseMenu!: boolean
  //是否展示按项目购买的课程
  @Prop({ default: false }) IsBuyProjectMenu!: boolean
  //SPU商品Code（【按项目购买】tab需要传值）
  @Prop() spuCode!: string
  //SPU版本（【按项目购买】tab需要传值）
  @Prop() spuVersion!: string
  //项目id（【按项目购买】tab需要传值）
  @Prop() projectId!: string
  //机构id（【按项目购买】tab需要传值）
  @Prop() orgId!: string
  //是否是中间平台（【按项目购买】tab需要传值）
  @Prop({ default: false }) middlePlatform!: boolean
  //sku的code集合（【按项目购买】tab需要传值）
  @Prop({ type: Array, default: [] }) skuCodeList!: any
  //不控制最大知识挑选数量时，把前面所有所选择的知识赋值给 beforeKng 会从知识表中排除之前所选择知识，并且在返回时将所有前面所选择的知识一并返回;控制数量时，可以当做初始值传入，默认列表会勾选当前值
  @Prop({ type: Array, default: [] }) beforeKng!: any
  //是否固定选择课程类型
  @Prop({ default: false }) isDisabled!: boolean
  //是否存在"隐藏的知识"的 checkbox
  @Prop({ default: true }) isRemoveHide!: boolean
  //是否存在"删除的知识"的 checkbox
  @Prop({ default: false }) isRemoveDelete!: boolean
  //默认请求知识的权限（0-浏览、2-分发 项目和创建课程包必须走分发权限，其余视内部业务而定）
  @Prop({ default: 0 }) pmType!: number
  //是否添加上传文件功能
  @Prop({ default: false }) isOpenUpload!: boolean
  //是否隐藏知识库学分
  @Prop({ default: false }) isHideScore!: boolean
  //是否不需要权限的控制
  @Prop({ default: false }) isNoNeedAuth!: boolean
  //是否可以选择新知识库的知识
  @Prop({ default: false }) canSelectLibKng!: boolean
  //学院ID
  @Prop() collegeId!: string
  //是否需要筛选审核状态
  @Prop({ default: false }) isAudit!: boolean
  //是否需要筛选创建时间
  @Prop({ default: false }) isCreateTime!: boolean
  //是否过滤未开启的课堂中的目录数据和课程数据
  @Prop({ default: false }) filterClosedCollegeData!: boolean
  //是否跨机构选择
  @Prop({ default: false }) groupSelect!: boolean
  //集团机构的数据权限code
  @Prop() orgDataPermissionCode!: string
  //集团机构的页面导航code
  @Prop() orgFunctionCode!: string
  //是否显示所有按次购买的数据
  @Prop({ default: false }) showAllBuyCount!: boolean
  //手动禁止选中的知识id数组
  @Prop({ type: Array, default: [] }) disabledRowIds!: any
  //云环境选择 0:私有云, 1:公有云, 2：全部
  @Prop({ default: 2 }) env!: number
  //0-课程、1-文档、2-视频、3-音频、4-微课、5-SCORM、6-HTML、7-压缩包(M1 无压缩包)、8-外链
  @Prop({ type: Array, default: [] }) includeTypes!: any

  open() {
    this.openPage = true
  }

  getKngInfo(list) {
    console.log(list, '选择课程数据')
    this.$emit('input', list)
    this.$emit('confirm', list)
    this.openPage = false
  }

  closePage() {
    this.openPage = false
  }
}
