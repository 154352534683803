
















































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import FormMixin from '@/amui/mixins/formMixin'
@Component({
  name: 'AmBizTeacherSelector',
})
export default class AmBizTeacherSelector extends Mixins(FormMixin) {
  static componentName = 'AmBizTeacherSelector'
  visible = false
  selectValue = []

  //选择形式
  @Prop({ default: 'button' }) selectType!: string
  //按钮文案
  @Prop({ default: '选择讲师' }) buttonText!: string
  //按钮类型
  @Prop({ default: 'primary' }) buttonType!: string
  @Prop({ default: 'medium' }) readonly buttonSize!: string

  //讲师类型（-1：不限制 0：内部讲师 1：外部讲师）
  @Prop({ default: -1 }) type!: number
  //页面的导航编号
  @Prop() functionCode!: string
  //选讲师的数据权限点
  @Prop() dataPermissionCode!: string
  //选人的数据权限点
  @Prop() selectUserPermissionCode!: string
  //是否显示授课员工tab
  @Prop({ default: false }) allowSelectTeachUser!: boolean
  //是否可以有选人模式
  @Prop({ default: false }) allowSelectUser!: boolean
  //是否强制单选模式， 默认会根据传入的数据类型修改。true时强制单选模式
  @Prop() singleMode!: boolean
  //是否能选择所有状态的数据（会包含禁用数据）
  @Prop({ default: false }) showAll!: boolean
  //是否是共享资源（集团版）
  @Prop({ default: false }) shareSource!: boolean
  //是否选择平台机构（集团版）
  @Prop({ default: false }) selectOrg!: boolean
  //是否集团版
  @Prop({ default: false }) enableGroupCorp!: boolean
  //是否切换到新组件模式
  @Prop({ default: false }) newReq!: boolean
  //是否显示预备讲师
  @Prop({ default: false }) prepared!: boolean
  //传入面授课程id,不传默认不展示认证讲师tab列
  @Prop() courseId!: string
  //是否报表专用
  @Prop({ default: false }) isRpt!: boolean
  //是否部门树统计数量，通部门树count属性
  @Prop({ default: false }) deptCount!: boolean

  created() {
    this.selectType === 'input' && (this.selectValue = this.value)
  }

  open() {
    this.visible = true
    this.selectType === 'input' && (this.value = this.selectValue)
  }

  cancel() {
    this.visible = false
    this.value = []
    this.$emit('cancel')
  }

  confirm() {
    console.log(this.value, '选择讲师数据')
    this.$emit('input', this.value)
    this.$emit('confirm', this.value)
    this.selectType === 'input' && (this.selectValue = this.value)
    this.visible = false
  }
}
