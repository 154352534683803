

















import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'AmRicheditor',
})
export default class AmRicheditor extends Vue {
  static componentName = 'AmRicheditor'

  @Prop(String) readonly appCode?: string //微应用编码，比如：o2o、ote
  @Prop(String) readonly orgCode?: string //机构编码，比如:xxv2
  @Prop(String) readonly moduleName?: string //板块名称，比如：knh
  @Prop(String) readonly functionName?: string //功能名称，比如：video
  @Prop({ default: '' }) readonly content!: string
  @Prop({ default: false }) readonly linkImg!: boolean
  @Prop(Number) readonly maxLength?: number
  @Prop(Number) readonly minLength?: number
  @Prop({ default: false }) readonly filterContent!: boolean
  @Prop({ default: [] }) readonly menus!: string[]
}
