









import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'AmFeDivider',
})
export default class AmFeDivider extends Vue {
  static componentName = 'AmFeDivider'

  @Prop({ default: 'horizontal' }) readonly direction!: string
  @Prop({ default: 'center' }) readonly contentPosition!: string
}
