




































import { Component, Mixins } from 'vue-property-decorator'
import AmDropdownMenuMixin from './mixin'

@Component({
  name: 'AmDropdownMenuForEdit',
})
export default class AmDropdownMenuForEdit extends Mixins(AmDropdownMenuMixin) {
  static componentName = 'AmDropdownMenuForEdit'

  editPanelIsShowing = false
  get editIconClass() {
    return this.editPanelIsShowing && this.isCustom
      ? 'yxt-icon-arrow-up'
      : 'yxt-icon-arrow-down'
  }

  showEditPanel() {
    this.editPanelIsShowing = true
  }
  hideEditPanel() {
    this.editPanelIsShowing = false
  }
}
