




























import { Component, Prop, Mixins } from 'vue-property-decorator'
import FormMixin from '@/amui-fe/mixins/formMixin'
@Component({
  name: 'AmFeInputSearchField',
})
export default class AmFeInputSearchField extends Mixins(FormMixin) {
  static componentName = 'AmFeInputSearchField'

  // @Prop(String) readonly value!: string
  @Prop({ default: '请输入' }) readonly placeholder!: string
  // @Prop(String) readonly iconAlign!: string
  @Prop({ default: false }) readonly autofocus!: boolean
  @Prop({ default: true }) readonly clearable!: boolean

  get label_i18n() {
    return this.label === '搜索框'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_inputsearchfield_label',
          '搜索框'
        )
      : this.label
  }
}
