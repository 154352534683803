












import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { getAppPageInfo } from '../util'
@Component({
  name: 'AmBreadCrumb',
})
export default class AmBreadCrumb extends Vue {
  static componentName = 'AmBreadCrumb'

  @Prop() readonly options!: any[]
  @Prop() readonly separator?: string
  @Prop() readonly separatorClass?: string

  // 2023-3-22 新增属性
  @Prop(Function) readonly navigateToSetter?: (item: any) => any
  key = new Date().getTime()

  get separatorIcon() {
    return this.separatorClass ? 'yxt-icon-' + this.separatorClass : ''
  }
  @Watch('separator')
  separatorChange() {
    if (this.separatorIcon) return
    this.updateKey()
  }
  @Watch('separatorIcon')
  separatorIconChange() {
    this.updateKey()
  }

  updateKey() {
    this.key = new Date().getTime()
  }

  navigateTo(path) {
    if (this.navigateToSetter) {
      this.navigateToSetter(path)
      return
    }
    if (!path.to) return
    const hash = window.location.hash
    const isCompile = hash.startsWith('#/page_')
    const pid = String(path.to).includes('/')
      ? String(path.to).split('/')[1]
      : ''
    let target = '_blank'
    let href = ''
    // 是否编译
    if (isCompile) {
      if (!pid) return
      href = window.location.href.replace(hash, `#/page_${pid}`)
      target = '_self'
    } else {
      target = hash.startsWith('#/editor') ? '_blank' : '_self'
      href = `${window.location.origin}/#/preview/${path.to}`
    }
    window.open(href, target, path.replace)
  }
}
