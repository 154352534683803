






















































import { Component, Prop, Mixins } from 'vue-property-decorator'
import FormMixin from '@/amui-fe/mixins/formMixin'
@Component({
  name: 'AmFeInputField',
})
export default class AmFeInputField extends Mixins(FormMixin) {
  static componentName = 'AmFeInputField'

  //是否可以远程搜索
  @Prop({ default: false }) readonly remoteSearch!: boolean

  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get(
        'apaas_amui_formfieldinput_placeholder',
        '请输入'
      )
    },
  })
  readonly placeholder!: string
  @Prop({ default: 'text' }) readonly type!: string
  @Prop({ default: 0 }) readonly minlength!: number
  @Prop({ default: 99 }) readonly maxlength!: number
  @Prop({ default: false }) readonly showWordLimit!: boolean
  @Prop({ default: false }) readonly clearable!: boolean
  @Prop({ default: 2 }) readonly rows!: number

  // 远程搜索配置
  //输入建议对象中用于显示的键名
  @Prop({ default: 'value' }) readonly valueKey!: string
  //获取输入建议的去抖延时
  @Prop({ default: 300 }) readonly debounce!: number
  //菜单弹出位置
  @Prop({ default: 'bottom-start' }) readonly placement!: string
  //返回输入建议的方法，仅当你的输入建议数据 resolve 时，通过调用 callback(data:[]) 来返回它
  @Prop(Function) readonly fetchSuggestions?: () => any
  //Autocomplete 下拉列表的类名
  @Prop() readonly popperClass!: string
  //是否在输入框 focus 时显示建议列表
  @Prop({ default: true }) readonly triggerOnFocus!: boolean
  @Prop() readonly name!: string
  //在输入没有任何匹配建议的情况下，按下回车是否触发 select 事件
  @Prop({ default: false }) readonly selectWhenUnmatched!: boolean
  //输入框头部图标
  @Prop() readonly prefixIcon!: string
  //输入框尾部图标
  @Prop() readonly suffixIcon!: string
  //是否隐藏远程加载时的加载图标
  @Prop({ default: false }) readonly hideLoading!: boolean
  //是否将下拉列表插入至 body 元素。在下拉列表的定位出现问题时，可将该属性设置为 false
  @Prop({ default: true }) readonly popperAppendToBody!: boolean
  //是否默认突出显示远程搜索建议中的第一项
  @Prop({ default: false }) readonly highlightFirstItem!: boolean

  get label_i18n() {
    return this.label === '输入框'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_inputfield_label',
          '输入框'
        )
      : this.label
  }
}
