





import throttle from 'throttle-debounce/debounce'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { getScrollContainer } from '../util'

@Component({
  name: 'AmInfiniteScroll',
})
export default class AmInfiniteScroll extends Vue {
  static componentName = 'AmInfiniteScroll'

  @Prop({ type: Number, default: 200 }) readonly delay!: number
  @Prop({ type: Number, default: 0 }) readonly distance!: number
  @Prop({ type: Boolean, default: true }) readonly immediate!: boolean
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean
  @Prop({
    type: Function,
    default: (over: boolean) => {
      over
    },
  })
  readonly overpage!: (over: boolean) => { over }
  @Prop({ type: Function, default: () => {} }) readonly load!: () => any

  scope = 'ElInfiniteScroll'
  container: HTMLElement = window.document.documentElement
  observer: any = null
  onScroll = () => {}
  attributes = {
    delay: {
      type: Number,
      default: 200,
    },
    distance: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    immediate: {
      type: Boolean,
      default: true,
    },
    overpage: {
      type: Function,
      default: () => {},
    },
  }

  getStyleComputedProperty(element, property): string {
    if (element === window) {
      element = document.documentElement
    }

    if (element.nodeType !== 1) {
      return '0'
    }
    // NOTE: 1 DOM access here
    const css = window.getComputedStyle(element, null)
    return css[property]
  }

  entries(obj) {
    return Object.keys(obj || {}).map(key => [key, obj[key]])
  }

  getPositionSize(el, prop) {
    return el === window || el === document
      ? document.documentElement[prop]
      : el[prop]
  }

  getOffsetHeight(el) {
    return this.getPositionSize(el, 'offsetHeight')
  }

  getClientHeight(el) {
    return this.getPositionSize(el, 'clientHeight')
  }

  getElementTop(el): number {
    return el.getBoundingClientRect().top
  }

  handleScroll() {
    const { distance, disabled, overpage, container, observer, load } = this
    overpage(container.offsetHeight < container.scrollHeight) // 计算滚动区是否应该出现加载完毕的标识

    if (disabled) return

    let shouldTrigger = false

    if (container === this.$el) {
      // be aware of difference between clientHeight & offsetHeight & window.getComputedStyle().height
      const scrollBottom = container.scrollTop + this.getClientHeight(container)
      shouldTrigger = container.scrollHeight - scrollBottom <= distance
    } else {
      const heightBelowTop =
        this.getOffsetHeight(this.$el) +
        this.getElementTop(this.$el) -
        this.getElementTop(container)
      const offsetHeight = this.getOffsetHeight(container)
      const borderBottom = Number.parseFloat(
        this.getStyleComputedProperty(container, 'borderBottomWidth')
      )
      shouldTrigger = heightBelowTop - offsetHeight + borderBottom <= distance
    }

    if (shouldTrigger && typeof load === 'function') {
      load()
    } else if (observer) {
      observer.disconnect()
      this.observer = null
    }
  }
  mounted() {
    const el = this.$el
    // only include vertical scroll
    this.container = getScrollContainer(el, true)
    this.onScroll = throttle(this.delay, this.handleScroll)
    if (this.container) {
      this.container.addEventListener('scroll', this.onScroll)

      if (this.immediate) {
        this.observer = new MutationObserver(this.onScroll)
        this.observer.observe(this.container, {
          childList: true,
          subtree: true,
        })
        this.onScroll()
      }
    }
  }
  destroy() {
    if (this.container) {
      this.container.removeEventListener('scroll', this.onScroll)
    }
  }
}
