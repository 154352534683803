import { Component, Vue, Prop } from 'vue-property-decorator'
import '../../assets/css/AmFeDropdownMenu.scss'

@Component({
  name: 'AmFeDropdownMenuMixin',
})
export default class extends Vue {
  @Prop({ type: String, default: '下拉菜单' }) readonly title!: string
  @Prop(Boolean) readonly customElm!: boolean
  @Prop(Boolean) readonly isCustom!: boolean
  @Prop(String) readonly placement!: string
  @Prop(String) readonly trigger!: string
  @Prop(Boolean) readonly hideOnClick!: boolean
  @Prop(Boolean) readonly disabled!: boolean
  @Prop({ default: 'primary' }) readonly type!: string
  @Prop(String) readonly size!: string
  @Prop({ default: '' }) readonly icon!: Record<string, any>
  @Prop(String) readonly width?: string
  @Prop(Array) readonly menuItems!: {
    label: string
    command: string
    icon: string
    disabled: boolean
    divided: boolean
  }[]

  iconClass: string = 'yxt-icon-arrow-down'

  get panelStyle() {
    return {
      width: this.isCustom ? this.width : 'auto',
      minWidth: this.isCustom ? this.width : 'auto',
    }
  }

  get iconObj() {
    // 旧数据兼容（原为string类型）
    return this.icon
      ? typeof this.icon == 'string'
        ? JSON.parse(this.icon)
        : this.icon
      : {}
  }

  get iconType() {
    return this.iconObj?.iconType || ''
  }
  get iconName() {
    return this.iconObj?.value || ''
  }

  handleVisibleChange(visible) {
    if (!this.customElm) return
    if (visible) this.iconClass = 'yxt-icon-arrow-up'
    else this.iconClass = 'yxt-icon-arrow-down'
  }
}
