











































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import FormMixin from '@/amui/mixins/formMixin'
@Component({
  name: 'AmBizUserSelector',
})
export default class AmBizUserSelector extends Mixins(FormMixin) {
  static componentName = 'AmBizUserSelector'
  visible = false
  userList = []

  //选择形式
  @Prop({ default: 'button' }) selectType!: string
  //按钮文案
  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get(
        'apaas_amui_userfield_label',
        '选择用户'
      )
    },
  })
  buttonText!: string
  //按钮类型
  @Prop({ default: 'primary' }) buttonType!: string
  @Prop({ default: 'medium' }) readonly buttonSize!: string
  //组件功能数据配置
  @Prop() tabs!: any
  //人数限制
  @Prop({ default: 0 }) limit!: number
  //功能点code
  @Prop() functionCode!: string
  //数据权限code
  @Prop() dataPermissionCode!: string
  //是否开启集团版
  @Prop({ default: false }) enableGroupCorp!: boolean
  //人数限制
  @Prop() groupLimit!: number
  //高级选人的筛选条件是否显示部门
  @Prop({ default: true }) isShowDeptment!: boolean
  //集团版下的机构id名称
  @Prop() targetOrgId!: string
  //集团版机构选择组件是否禁用
  @Prop({ default: false }) disabledOrgSelect!: boolean
  //集团版选人组件各平台是否独立选择
  @Prop({ default: false }) isOrgSelectAlone!: boolean
  //群Id，无该参数不显示群成员tab
  @Prop() projectGroupId!: string
  //开启动态用户组tab后的提示信息
  @Prop() relationTips!: string

  open() {
    this.visible = true
  }

  confirm() {
    let _ref: any = this.$refs['amBizUserSelector']
    if (_ref?.processing) {
      this.$message.warning('正在进行中，请稍后')
      return
    }
    _ref
      ?.getSelected()
      .then(result => {
        console.log(result, '选择用户数据')
        this.$emit('input', result)
        this.$emit('confirm', result)
        this.visible = false
      })
      .catch(e => {
        console.log(e)
      })
  }
}
