import { render, staticRenderFns } from "./AmBaseRichEditor.vue?vue&type=template&id=234d0745&scoped=true"
import script from "./AmBaseRichEditor.vue?vue&type=script&lang=ts"
export * from "./AmBaseRichEditor.vue?vue&type=script&lang=ts"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./AmBaseRichEditor.vue?vue&type=style&index=1&id=234d0745&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "234d0745",
  null
  
)

export default component.exports