
import { Component, Mixins } from 'vue-property-decorator'
import CellContainer from '../components/CellContainer.vue'

@Component({
  name: 'AmCellContainer',
})
export default class AmCellContainer extends Mixins(CellContainer) {
  static componentName = 'AmCellContainer'

  parentType: '' | 'AmRowContainer' | 'AmColContainer' = ''

  get isCol() {
    return this.parentType === 'AmColContainer'
  }

  get isRow() {
    return this.parentType === 'AmRowContainer'
  }

  get style() {
    let parent = this.$parent
    while (parent) {
      let name = (parent as any).compName

      if (name === 'AmRowContainer' || name === 'AmColContainer') {
        this.parentType = name
        break
      }

      parent = parent.$parent
    }

    let styleflexBasis: {
      flexGrow: number
      flexShrink: number
      [properyName: string]: string | number
    } = {
      flexGrow: 0,
      flexShrink: 0,
    }

    let size = this.isRow ? this.$el?.clientWidth : this.$el?.clientHeight

    if (this.flexBasis === 'fixed') {
      styleflexBasis.flexBasis = `${size}px`
    }

    if (this.width) {
      let width =
        typeof this.width === 'number' ? this.width + 'px' : this.width
      styleflexBasis.flexBasis = width
    }

    if (this.flexBasis === 'fixed') {
      return styleflexBasis
    } else {
      return {}
    }
  }
}
