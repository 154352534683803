



















import { Component, Mixins } from 'vue-property-decorator'
import FormMixin from '@/amui-fe/mixins/formMixin'
@Component({
  name: 'AmFeFormElementContainer',
})
export default class AmFeFormElementContainer extends Mixins(FormMixin) {
  static componentName = 'AmFeFormElementContainer'

  get label_i18n() {
    return this.label === '表单项'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_formelementcontainer_label',
          '表单项'
        )
      : this.label
  }
}
