

















































import { Component, Mixins } from 'vue-property-decorator'
import FormMixin from '@/amui/mixins/formMixin'
@Component({
  name: 'AmTagField',
})
export default class AmTagField extends Mixins(FormMixin) {
  static componentName = 'AmTagField'

  visible = false
  inputValue = ''

  get tags() {
    return !!this.value?.trim() ? this.value.split(',') : []
  }

  setTags(v) {
    this.change(v.join(','))
  }

  handleClose(tag) {
    if (this.disabled || this.readonly) {
      return
    }
    this.tags.splice(this.tags.indexOf(tag), 1)
    this.setTags(this.tags)
  }

  showInput() {
    if (this.disabled || this.readonly) {
      return
    }
    this.visible = true
    this.$nextTick(() => {
      ;(this.$refs.saveTagInput as any).$refs.input.focus()
    })
  }

  handleInputConfirm() {
    let inputValue = this.inputValue
    if (inputValue) {
      this.tags.push(inputValue)
      this.setTags(this.tags)
    }
    this.visible = false
    this.inputValue = ''
  }

  get label_i18n() {
    return this.label === '选择标签'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_tagfield_label',
          '选择标签'
        )
      : this.label
  }
}
