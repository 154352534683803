import { Vue, Component, Prop } from 'vue-property-decorator'
@Component({
  name: 'LinkMixin',
})
export default class LinkMixin extends Vue {
  @Prop({ default: 'default' }) type!: string
  @Prop({ default: false }) underline!: boolean
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: 'page' }) hrefType!: string
  @Prop({ default: '' }) href!: string
  @Prop({ default: false }) target!: boolean
  @Prop({ default: () => [] }) query!: Array<Record<string, any>>

  get isAppMaker() {
    return (window as any).lowcode.__IS_APP_MAKER__
  }

  get linkHref() {
    if (!this.href) return undefined
    let href = ''
    if (this.hrefType == 'url') {
      href = this.href
    } else {
      const { hash, href: locationHref, origin } = window.location
      const [appid, pid] = String(this.href).includes('/')
        ? (String(this.href).split('/') as any[])
        : []
      if (this.isAppMaker) {
        href = `${origin}/#/preview/${appid}/page/${pid}`
      } else {
        const url = hash.includes('#') ? `#/page_${pid}` : `/page_${pid}`
        href = locationHref.replace(hash, url)
      }
    }
    if (this.query.length > 0) {
      let query = '?'
      for (let i = 0; i < this.query.length; i++) {
        let key = this.query[i].label
        let value = this.query[i].value
        query += `${key}=${value}${i == this.query.length - 1 ? '' : '&'}`
      }
      href += query
    }
    return href
  }

  get linkTarget() {
    return this.isAppMaker ? true : this.target
  }
}
