






import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'AmRouterView',
})
export default class AmStep extends Vue {
  static componentName = 'AmRouterView'
}
