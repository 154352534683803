

















































import { Component, Mixins, Prop } from 'vue-property-decorator'
import FormMixin from '@/amui/mixins/formMixin'

@Component({
  name: 'AmRadioFeild',
})
export default class AmRadioFeild extends Mixins(FormMixin) {
  static componentName = 'AmRadioFeild'
  ruleForm = {
    value: '',
  }

  // @Prop([String, Number, Boolean]) readonly value!: string | number | boolean
  @Prop([String, Number, Boolean]) readonly options!: string | number | boolean
  @Prop(String) readonly shape!: string
  @Prop(String) readonly itemDirection!: string
  @Prop(String) readonly border!: boolean
}
