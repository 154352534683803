





import { Vue, Component, Prop, Inject } from 'vue-property-decorator'

@Component({
  name: 'AmCustomRender',
})
export default class AmCustomRender extends Vue {
  static componentName = 'AmCustomRender'

  @Prop({ type: String, default: 'template' }) readonly renderType?: string
  @Prop({ type: String, default: '' }) readonly renderTemplate?: string
  @Prop(Function) readonly renderFunction?: any
  @Prop(Object) scope?: any

  @Inject('amRootVm') readonly amRootVm!: Vue

  get rootVm() {
    let _rootVM: any = this.amRootVm || {}
    while (_rootVM.amRootVm) {
      _rootVM = _rootVM.amRootVm
    }
    if (!_rootVM.global) _rootVM.global = {}
    return _rootVM
  }

  get isTemplateRender() {
    return this.renderType === 'template'
  }

  get isFunctionRender() {
    return this.renderType === 'function'
  }

  get customRenderComponent() {
    if (this.isTemplateRender && this.renderTemplate) {
      const renderTemplate = unescape(this.renderTemplate || '')
        .replace(/this\./g, 'rootVm.')
        // 兼容老的写法
        .replace(/\$\./g, 'rootVm.')
      const { rootVm } = this
      return Vue.extend({
        template: renderTemplate,
        props: {
          data: Object,
        },
        data() {
          return {
            rootVm,
          }
        },
      })
    } else if (this.isFunctionRender && this.renderFunction) {
      let _rootVM: any = this || {}
      while (!_rootVM[this.renderFunction] && _rootVM.amRootVm) {
        _rootVM = _rootVM.amRootVm
      }
      let renderFunc = _rootVM[this.renderFunction]
      if (!renderFunc) {
        return null
      }

      return Vue.extend({
        functional: true,
        props: {
          data: Object,
        },
        render: (h, context) => renderFunc(h, context.props.data),
      })
    } else {
      return null
    }
  }
}
