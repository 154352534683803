





import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'Template',
})
export default class Template extends Vue {
  static componentName = 'Template'
}
