// 布局组件
import AmFeLayout from './layout/AmFeLayout.vue'
import AmFeContainer from './layout/AmFeContainer.vue'
import AmFeGroup from './layout/AmFeGroup.vue'
import AmFeSplitLayout from './layout/AmFeSplitLayout.vue'
import AmFeExpandSider from './layout/AmFeExpandSider.vue'
import AmFeCellContainer from './layout/AmFeCellContainer.vue'
import AmFeSlotComponent from './layout/AmFeSlotComponent.vue'
// 基础组件
import AmFeText from './base/AmFeText.vue'
import AmFeButton from './base/AmFeButton.vue'
import AmFeImage from './base/AmFeImage.vue'
import AmFeIcon from './base/AmFeIcon.vue'
import AmFeLink from './base/AmFeLink.vue'
import AmFeLinkContainer from './base/AmFeLinkContainer.vue'
import AmFeDialog from './base/AmFeDialog.vue'
import AmFeMark from './base/AmFeMark.vue'
// 表单组件
import AmFeCheckboxField from './form/AmFeCheckboxField.vue'
import AmFeDatePickerField from './form/AmFeDatePickerField.vue'
import AmFeDateRangePickerField from './form/AmFeDateRangePickerField.vue'
import AmFeFormElementContainer from './form/AmFeFormElementContainer.vue'
import AmFeInputField from './form/AmFeInputField.vue'
import AmFeInputSearchField from './form/AmFeInputSearchField.vue'
import AmFeMultiSelectField from './form/AmFeMultiSelectField.vue'
import AmFeNumberField from './form/AmFeNumberField.vue'
import AmFeRadioField from './form/AmFeRadioField.vue'
import AmFeRateField from './form/AmFeRateField.vue'
import AmFeSelectField from './form/AmFeSelectField.vue'
import AmFeTagField from './form/AmFeTagField.vue'
import AmFeTimePickerField from './form/AmFeTimePickerField.vue'
import AmFeUploadField from './form/AmFeUploadField.vue'
// import AmFePage from './layout/base/AmFePage.vue'
// import AmFeTemplate from './layout/base/AmFeTemplate.vue'
// import AmFeDrawer from './base/AmFeDrawer.vue'
// 数据
import AmFeSlotTable from './data/AmFeSlotTable.vue'
import AmFeSlotTableColumn from './data/AmFeSlotTableColumn.vue'
import AmFeSlotTableColumnEdit from './data/AmFeSlotTableColumnEdit.vue'
import AmFeTable from './data/AmFeTable.vue'
import AmFeTableColumn from './data/AmFeTableColumn.vue'

// 高级
import AmFeBreadCrumb from './senior/AmFeBreadCrumb.vue'
import AmFeDivider from './senior/AmFeDivider.vue'
import AmFeDropdownMenu from './senior/AmFeDropdownMenu/Index.vue'
import AmFeDropdownMenuForEdit from './senior/AmFeDropdownMenu/Edit.vue'
import AmFeFrame from './senior/AmFeFrame.vue'
import AmFeHtml from './senior/AmFeHtml.vue'
import AmFeMenu from './senior/AmFeMenu'
import AmFePopupContainer from './senior/AmFePopupContainer/Index.vue'
import AmFePopupContainerForEdit from './senior/AmFePopupContainer/Edit.vue'
import AmFePopupContainerItem from './senior/AmFePopupContainer/Item.vue'
import AmFeStep from './senior/AmFeStep.vue'
import AmFeTooltip from './senior/AmFeTooltip.vue'

// 依赖
import AmFePage from './layout/base/AmFePage.vue'
import AmFeTemplate from './layout/base/AmFeTemplate.vue'
import AmFeLayoutColumn from './layout/reply/AmFeLayoutColumn.vue'
import AmFeSplitColumn from './layout/reply/AmFeSplitColumn.vue'
import AmFeChildContainer from './layout/reply/AmFeChildContainer.vue'
import AmFeColContainer from './layout/reply/AmFeColContainer.vue'
import AmFeRowContainer from './layout/reply/AmFeRowContainer.vue'

// const version = process.env.VERSION || require('../../package.json').version

const components: any[] = [
  // 布局组件
  AmFeLayout,
  AmFeContainer,
  AmFeGroup,
  AmFeSplitLayout,
  AmFeExpandSider,
  AmFeCellContainer,
  AmFeSlotComponent,
  // 基础组件
  AmFeText,
  AmFeIcon,
  AmFeButton,
  AmFeLink,
  AmFeLinkContainer,
  AmFeImage,
  AmFeDialog,
  AmFeMark,
  // 表单组件
  AmFeInputField,
  AmFeDatePickerField,
  AmFeSelectField,
  AmFeMultiSelectField,
  AmFeRateField,
  AmFeInputSearchField,
  AmFeNumberField,
  AmFeTimePickerField,
  AmFeTagField,
  AmFeDateRangePickerField,
  AmFeFormElementContainer,
  AmFeRadioField,
  AmFeCheckboxField,
  AmFeUploadField,
  // 数据
  AmFeSlotTable,
  AmFeSlotTableColumn,
  AmFeSlotTableColumnEdit,
  AmFeTable,
  AmFeTableColumn,
  // 高级
  AmFeMenu,
  AmFeTooltip,
  AmFePopupContainer,
  AmFePopupContainerForEdit,
  AmFeDropdownMenuForEdit,
  AmFePopupContainerItem,
  AmFeDropdownMenu,
  AmFeBreadCrumb,
  AmFeStep,
  AmFeDivider,
  AmFeFrame,
  AmFeHtml,
  // 依赖组件
  AmFePage,
  AmFeTemplate,
  AmFeLayoutColumn,
  AmFeSplitColumn,
  AmFeChildContainer,
  AmFeColContainer,
  AmFeRowContainer,
]

export default components
