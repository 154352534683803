












































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import FormMixin from '@/amui/mixins/formMixin'
@Component({
  name: 'AmBizRangeSelector',
})
export default class AmBizRangeSelector extends Mixins(FormMixin) {
  static componentName = 'AmBizRangeSelector'
  visible = false
  selectValue = []

  //选择形式
  @Prop({ default: 'button' }) selectType!: string
  //按钮文案
  @Prop({ default: '选择范围' }) buttonText!: string
  //按钮类型
  @Prop({ default: 'primary' }) buttonType!: string
  @Prop({ default: 'medium' }) readonly buttonSize!: string
  //组件功能数据配置
  @Prop() tabs!: any
  //是否多选
  @Prop({ default: true }) multiple!: boolean
  //人数限制
  @Prop({ default: 999 }) limit!: number
  //功能点code
  @Prop() functionCode!: string
  //数据权限code
  @Prop() dataPermissionCode!: string
  //控制v-model的绑定格式，使用array则会返回一个数组
  @Prop({ default: 'object' }) model!: string
  //是否开启集团版
  @Prop({ default: false }) enableGroupCorp!: boolean
  //集团版下是否隐藏/禁用机构选择器
  @Prop() visibleOrgSelector!: string
  //集团版下机构选择器哪些数据禁用不可选
  @Prop({ type: Array, default: [] }) disabledOrgIds!: any
  //集团版下机构选择器哪些数据可展示
  @Prop({ type: Array, default: [] }) visibleOrgIds!: any
  //集团版下快速选人是否根据不同机构独立区分
  @Prop({ default: false }) isOrgSelectAlone!: boolean
  //是否显示部门筛选条件
  @Prop({ default: true }) isShowDeptment!: boolean
  //集团版下的机构id名称
  @Prop() targetOrgId!: string

  created() {
    this.selectType === 'input' && (this.selectValue = this.value)
  }

  open() {
    this.visible = true
  }

  cancel() {
    this.visible = false
    this.value = []
    this.$emit('cancel')
  }

  async confirm() {
    // 数据限制校验
    const isValidate = await (
      this.$refs['amBizRangeSelector'] as any
    )?.validate()
    if (isValidate) {
      const data = await (
        this.$refs['amBizRangeSelector'] as any
      )?.getSelectDataArray()
      this.$emit('input', data)
      this.$emit('confirm', data)
      this.selectType === 'input' && (this.selectValue = data)
      this.visible = false
      console.log(data, '选择范围数据')
    }
  }
}
