import { render, staticRenderFns } from "./AmFrame.vue?vue&type=template&id=dda090e2&scoped=true"
import script from "./AmFrame.vue?vue&type=script&lang=ts"
export * from "./AmFrame.vue?vue&type=script&lang=ts"
import style0 from "./AmFrame.vue?vue&type=style&index=0&id=dda090e2&prod&scoped=true&lang=scss"
import style1 from "./AmFrame.vue?vue&type=style&index=1&id=dda090e2&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dda090e2",
  null
  
)

export default component.exports