
import { Component, Mixins } from 'vue-property-decorator'
import ChildContainer from '../../../amui/components/rely/ChildContainer.vue'

@Component({
  name: 'AmFeChildContainer',
})
export default class AmFeChildContainer extends Mixins(ChildContainer) {
  static componentName = 'AmFeChildContainer'
}
