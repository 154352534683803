
import { Component, Mixins } from 'vue-property-decorator'
import ColContainer from '../components/rely/ColContainer.vue'

@Component({
  name: 'AmColContainer',
})
export default class AmColContainer extends Mixins(ColContainer) {
  static componentName = 'AmColContainer'
}
