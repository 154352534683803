






























import { Component, Prop, Mixins, Model } from 'vue-property-decorator'
import FormMixin from '@/amui-fe/mixins/formMixin'
@Component({
  name: 'AmFeNumberField',
})
export default class AmFeNumberField extends Mixins(FormMixin) {
  static componentName = 'AmFeNumberField'

  @Prop({ default: Infinity }) readonly max!: number
  @Prop({ default: -Infinity }) readonly min!: number
  @Prop({ default: 0 }) readonly precision!: number
  @Prop({ default: 1 }) readonly step!: number
  @Prop({ default: false }) readonly thousandsSeparators!: boolean
  @Prop(String) readonly innerAfter?: string

  @Model('change', { default: undefined }) value!: number | undefined

  handleFormatter(value) {
    if (value) {
      // formatter千分位后精度实现，重新计算精度
      let valString: string = this.toPrecision(value, this.precision)
      if (this.thousandsSeparators) {
        let reg =
          valString.indexOf('.') > -1
            ? /(\d)(?=(\d{3})+\.)/g
            : /(\d)(?=(\d{3})+$)/g
        valString = valString.replace(reg, '$1,')
      }
      return `${valString}`
    } else {
      return value
    }
  }

  toPrecision(num: number, precision: number = 0) {
    let number =
      Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision)
    return parseFloat(number.toString()).toFixed(precision)
  }
  change() {
    this.$emit('change', this.value)
  }

  get label_i18n() {
    return this.label === '数字输入框'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_numberfield_label',
          '数字输入框'
        )
      : this.label
  }
}
