

















































































import { Component, Mixins } from 'vue-property-decorator'
import AmDropdownMenuMixinV2 from './mixin'

@Component({
  name: 'AmDropdownMenuForEditV2',
})
export default class AmDropdownMenuForEditV2 extends Mixins(
  AmDropdownMenuMixinV2
) {
  static componentName = 'AmDropdownMenuForEditV2'

  editPanelIsShowing = false
  get editIconClass() {
    return this.editPanelIsShowing && this.isCustom
      ? 'yxt-icon-arrow-up'
      : 'yxt-icon-arrow-down'
  }

  showEditPanel() {
    this.editPanelIsShowing = true
  }
  hideEditPanel() {
    this.editPanelIsShowing = false
  }
}
