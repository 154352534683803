






















































import { Component, Prop, Mixins } from 'vue-property-decorator'
import FormMixin from '@/amui/mixins/formMixin'

@Component({
  name: 'AmCheckboxField',
})
export default class AmCheckboxField extends Mixins(FormMixin) {
  static componentName = 'AmCheckboxField'

  @Prop({ default: 'column' }) itemDirection!: string
  @Prop({ default: 'default' }) shape!: string
  @Prop({ default: false }) readonly border!: boolean
  @Prop({ default: () => [] }) options!: Array<any>
  get label_i18n() {
    return this.label === '多选'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_checkboxfield_label',
          '多选'
        )
      : this.label
  }
}
