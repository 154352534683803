






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'LayoutColumn',
})
export default class LayoutColumn extends Vue {
  static componentName = 'LayoutColumn'

  @Prop({ default: 1 }) ratioVal!: number
  @Prop({ default: false }) isFirst!: boolean
  @Prop({ default: false }) isLast!: boolean

  get style() {
    return [
      {
        'grid-area': `span 1 / span ${this.ratioVal} / auto / auto`,
        // 'flex-flow': 'column nowrap',
        // 'justify-content': 'flex-start',
        // 'align-items': 'stretch',
        // 'display': 'flex',
        // 'position': 'relative',
        // 'min-width': 'auto',
      },
    ]
  }
}
