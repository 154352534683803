





















































import { Component, Mixins, Prop } from 'vue-property-decorator'
import tableBaseMixin from '../../amui/mixins/tableBaseMixin'
import AmFeTableColumnTemplate from './AmFeTableColumnTemplate.vue'
import AmFeSlotTableColumnEdit from './AmFeSlotTableColumnEdit.vue'

@Component({
  components: { AmFeTableColumnTemplate, AmFeSlotTableColumnEdit },
  name: 'AmFeSlotTableColumn',
})
export default class extends Mixins(tableBaseMixin) {
  static componentName = 'AmFeSlotTableColumn'

  @Prop(String) readonly type?: string
  @Prop(String) readonly prop?: string
  @Prop(String) readonly label?: string
  @Prop(String) readonly width?: string
  @Prop(String) readonly minWidth?: string
  @Prop(String) readonly align?: string
  @Prop(String) readonly showOverflowTooltip?: boolean
  @Prop(Boolean) readonly columnFold?: boolean
  @Prop(Boolean) readonly resizable?: boolean
  @Prop(String) readonly fixed?: boolean | string
  @Prop([Boolean, String]) readonly sortable?: boolean | string
  @Prop([Boolean, String]) readonly hideColumn?: boolean | string
  @Prop(Boolean) readonly tableFold?: boolean

  getContent(row: Record<string, any>) {
    return this.prop ? row[this.prop] : void 0
  }

  execButtonAction(action: string, scope: any) {
    if (!action) return
    const func = this.rootVm[action]
    if (typeof func !== 'function')
      throw new Error(`AmTableColumn action [${action}] is not a function!`)
    func(scope)
  }

  execDropdownMenuAction(scope: any, args: any[]) {
    const action = args[0]
    this.execButtonAction(action, scope)
  }
}
