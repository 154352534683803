































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'AmSlotTable',
})
export default class extends Vue {
  static componentName = 'AmSlotTable'

  @Prop(Array) readonly data!: []
  @Prop(String) readonly height?: string
  @Prop(String) readonly maxHeight?: string
  @Prop(String) readonly size?: string
  @Prop(Boolean) readonly stripe?: boolean
  @Prop(Boolean) readonly border?: boolean
  @Prop({ type: Boolean, default: true }) readonly showHeader!: boolean
  @Prop(Boolean) readonly virtual!: boolean
  @Prop(Boolean) readonly fold?: boolean // 折叠开关
  @Prop(Boolean) readonly foldLazy?: boolean // 折叠懒加载开关
  @Prop(Function) readonly foldLazyMethod!: () => void // 折叠懒加载函数
  @Prop(Boolean) readonly loading?: boolean
  @Prop(Number) readonly rowHeight?: number
  @Prop(String) readonly rowKey?: string
  @Prop(Object) readonly defaultSort?: { prop?: string; order?: string }
  @Prop(Object) readonly customColumnProps?: {
    enable: boolean
    fixedButton: boolean
    showDiasbledAttributes?: boolean
  }

  /**
   * yxt-table height / maxHeight 的设置存在 bug，通过以下方式进行 hack
   * 1. 初始时 height/maxHeight = undefined，设置有效值后表格更新正确，之后将值重设为 undefined 表格将不会更新
   * 2. 如果将 height/maxHeight 默认设置为空字符串，则表格在挂载渲染时会报错（但是不影响渲染）
   */

  tableHeight?: string = void 0
  tableMaxHeight?: string = void 0

  get tableData() {
    return Array.isArray(this.data) ? this.data : []
  }

  @Watch('height')
  @Watch('maxHeight')
  handleHeightChange() {
    this.tableHeight = this.height || ''
    this.tableMaxHeight = this.maxHeight || ''
  }

  created() {
    this.tableHeight = this.height
    this.tableMaxHeight = this.maxHeight
  }
}
