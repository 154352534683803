









































































































































































































import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import { Upload } from 'yxt-pc'

const imgType = [
  'png',
  'jpg',
  'jpeg',
  'bmp',
  'gif',
  'webp',
  'psd',
  'svg',
  'tiff',
]
@Component({
  name: 'AmUpload',
})
export default class AmUpload extends Vue {
  static componentName = 'AmUpload'

  @Ref('upload') readonly upload!: typeof Upload
  @Prop() readonly action!: string
  @Prop() readonly headers!: any
  @Prop() readonly httpRequest!: any
  @Prop({ default: [] }) readonly fileList!: any
  @Prop({ default: 9 }) readonly limit!: number
  @Prop({ default: true }) readonly autoUpload!: boolean
  @Prop({ default: true }) readonly multiple!: boolean
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ default: true }) readonly showFileList!: boolean
  @Prop({ default: 'normal' }) readonly uploadType!: string
  @Prop({ default: '上传附件' }) readonly buttonText!: string
  @Prop({ default: 'medium' }) readonly buttonSize!: string
  @Prop({ default: 'primary' }) readonly buttonType!: string
  @Prop({ default: '' }) readonly accept!: string
  @Prop({ default: 10 }) readonly maxFileSize!: number
  @Prop({ default: '178px' }) readonly pictureWidth!: string
  @Prop({ default: '178px' }) readonly pictureHeight!: string

  // 图片上传模式下文件
  public pictureUrl = ''

  /**
   * 卡片上传类型线上内容
   */
  get hint() {
    return (
      this.accept ||
      '.doc, .docx, .xls, .xlsx, .pdf, .rar, .zip, .txt, .jpg, .jpeg, .png, .gif, .msg'
    )
  }

  /**
   * 上传按钮尺寸
   */
  // get buttonSize() {
  //   const sizeMap = {
  //     mini: 'small',
  //     small: 'medium',
  //     medium: 'large',
  //   }
  //   return sizeMap?.[this.size] || 'medium'
  // }

  /**
   * 上传列表的类型
   */
  get listType() {
    return this.uploadType === 'card' ? 'picture-card' : 'text'
  }

  /**
   * 文件是否为图片类型
   */
  isImg(file) {
    const fileName: string = file.name
    const ext = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase()
    return imgType.includes(ext)
  }

  /**
   * 动态调整图片文件索引值
   */
  getImgList(file) {
    const uploadList = (this.upload as any)?.uploadFiles || []
    const imgList = uploadList
      .map(file => {
        return this.isImg(file) && file
      })
      .filter(item => item)
    const index = imgList.findIndex(item => item.uid === file.uid)
    if (index > -1) {
      const item = imgList[index]
      imgList.splice(index, 1)
      imgList.unshift(item)
    }
    return imgList.map(img => img.url)
  }

  /**
   * 图片上传成功回调
   */
  pictureSuccess(response, file, fileList) {
    const uploadList = (this.upload as any)?.uploadFiles || []
    const picture = uploadList[uploadList.length - 1]
    this.pictureUrl = URL.createObjectURL(picture.raw)
    this.$emit('success', response, file, fileList)
  }

  /**
   * 移除文件列表文件
   */
  handleClickRemove(file) {
    const index = (this.upload as any)?.uploadFiles.findIndex(
      item => item.uid === file.uid
    )
    if (index > -1) (this.upload as any)?.uploadFiles.splice(index, 1)
    this.$emit('remove', file, (this.upload as any)?.uploadFiles)
  }

  /**
   * 下载文件列表文件
   */
  handleDownload(file) {
    let aTag = document.createElement('a')
    aTag.download = file.name
    const href = URL.createObjectURL(file.raw)
    aTag.href = href
    aTag.click()
    URL.revokeObjectURL(href)
  }

  /**
   * 取消上传
   */
  handleAbort(file) {
    this.upload.abort(file)
    this.handleClickRemove(file)
  }

  /**
   * 文件上传前的钩子函数
   */
  beforeUpload(file) {
    const isLtMaxFileSize = file.size / 1024 / 1024 < this.maxFileSize
    if (!isLtMaxFileSize) {
      this.$message.error(`文件不能超过 ${this.maxFileSize}MB!`)
    }
    return isLtMaxFileSize
  }

  /**
   * 点击文件列表中已上传的文件时的钩子
   * @param file
   */
  handlePreview(file) {
    this.$emit('preview', file)
  }

  /**
   * 文件列表移除文件时的钩子
   * @param file
   * @param fileList
   */
  handleRemove(file, fileList) {
    this.$emit('remove', file, fileList)
  }

  /**
   * 文件上传成功时的钩子
   * @param response
   * @param file
   * @param fileList
   */
  handleSuccess(response, file, fileList) {
    this.$emit('success', response, file, fileList)
  }

  /**
   * 文件上传失败时的钩子
   * @param err
   * @param file
   * @param fileList
   */
  handleError(err, file, fileList) {
    this.$message.error(`${file.name}上传失败!`)
    this.$emit('error', err, file, fileList)
  }

  /**
   * 文件上传时的钩子
   * @param event
   * @param file
   * @param fileList
   */
  handleProgress(event, file, fileList) {
    this.$emit('progress', event, file, fileList)
  }

  /**
   * 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
   * @param file
   * @param fileList
   */
  handleChange(file, fileList) {
    if (this.isImg(file) && !file?.url) file.url = URL.createObjectURL(file.raw)
    this.$emit('change', file, fileList)
  }

  /**
   * 文件超出个数限制时的钩子函数
   */
  handleExceed(files, fileList) {
    this.$message.warning(
      `当前限制选择 ${this.limit} 个文件，本次选择了 ${
        files.length
      } 个文件，共选择了 ${files.length + fileList.length} 个文件`
    )
  }

  /**
   * 计算文件大小
   */
  getFileSize(size) {
    if (!size) return ''

    // byte
    const num = 1024.0

    if (size < num) return `(${size}B)`
    if (size < Math.pow(num, 2)) return `(${(size / num).toFixed(2)}K)`
    if (size < Math.pow(num, 3))
      return `(${(size / Math.pow(num, 2)).toFixed(2)}M)`
    if (size < Math.pow(num, 4))
      return `(${(size / Math.pow(num, 3)).toFixed(2)}G)`
    return `(${(size / Math.pow(num, 4)).toFixed(2)}T)`
  }

  parsePercentage(val) {
    return parseInt(val, 10)
  }
}
