import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'AmPopupContainerMixin',
})
export default class extends Vue {
  @Prop({ default: false }) readonly value!: boolean
  @Prop({ default: '气泡容器' }) readonly title!: string
  @Prop({ default: 'bottom' }) readonly placement!: string
  @Prop({ default: 'click' }) readonly trigger!: string
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ default: false }) readonly showScroll!: boolean
  @Prop({ default: true }) readonly visibleArrow!: boolean
  @Prop(String) readonly width?: string
  @Prop({ default: false }) readonly documentClickClose?: boolean
  @Prop({ default: '' }) readonly content!: string
}
