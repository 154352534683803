















import { Component, Mixins } from 'vue-property-decorator'
import AmImageMixin from '@/amui/mixins/AmImageMixin'

@Component({
  name: 'AmFeImage',
})
export default class AmFeImage extends Mixins(AmImageMixin) {
  static componentName = 'AmFeImage'
}
