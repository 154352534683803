


































import { Component, Mixins } from 'vue-property-decorator'
import ButtonMixin from '@/amui/mixins/buttonMixin'

@Component({
  name: 'AmFeButton',
})
export default class AmFeButton extends Mixins(ButtonMixin) {
  static componentName = 'AmFeButton'
}
