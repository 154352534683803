import AmUiComponents from '@/amui'
import AmUiFeComponents from '@/amui-fe'

const version = process.env.VERSION || require('../package.json').version

function install(vue) {
  ;[...AmUiComponents, ...AmUiFeComponents].forEach(component => {
    vue.component(component.componentName, component)
  })

  // 在打包成amui时，添加textEdit空指令
  process.env.BUILD_TYPE === 'AMUI' && vue.directive('textEdit', {})
}

/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export default {
  version,
  install,
}
