
import { Component, Mixins } from 'vue-property-decorator'
import ChildContainer from '../components/rely/ChildContainer.vue'

@Component({
  name: 'AmChildContainer',
})
export default class AmChildContainer extends Mixins(ChildContainer) {
  static componentName = 'AmChildContainer'
}
