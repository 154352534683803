





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
@Component({
  name: 'AmBaseRichEditor',
  components: {
    Editor,
    Toolbar,
  },
})
export default class AmBaseRichEditor extends Vue {
  static componentName = 'AmBaseRichEditor'

  @Prop() content!: string
  @Prop() menus!: any[]
  @Prop() maxLength!: number
  @Prop({ type: Boolean, default: true }) autoFocus!: boolean
  @Prop() uploadImage!: Record<string, any>

  @Watch('content', { immediate: true })
  contentChange(v) {
    this.html = v
  }

  editor
  html = ''
  get toolbarConfig() {
    return {
      toolbarKeys: this.menus,
    }
  }
  get editorConfig() {
    return {
      placeholder: '请输入内容...',
      autoFocus: this.autoFocus,
      maxLength: this.maxLength,
      MENU_CONF: {
        uploadImage: this.uploadImage,
      },
    }
  }
  mode = 'default'
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
  onCreated(editor) {
    this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    // console.log('menuKeys', editor.getAllMenuKeys())
    // console.log('uploadImage', editor.getMenuConfig('uploadImage'))
  }
  onChange(editor) {
    // console.log('onChange', editor.getHtml())
    this.$emit('change', editor.getHtml())
  }
  onFocus(editor) {
    // console.log('onFocus', editor)
    this.$emit('focus')
  }
  onBlur(editor) {
    // console.log('onBlur', editor.getHtml())
    this.$emit('blur', editor.getHtml())
  }
  onCustomPaste(editor, event, callback) {
    this.$emit('customPaste', editor, event, callback)
  }
  setHtml(html) {
    this.editor?.setHtml(html)
  }
  // append(text) {
  //   this.editor?.insertText(text)
  // }
  getHtml() {
    if (!this.editor) return ''
    return this.editor.getHtml()
  }
  getText() {
    if (!this.editor) return ''
    return this.editor.getText()
  }
}
