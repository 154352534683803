










import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'RowContainer',
})
export default class RowContainer extends Vue {
  static componentName = 'RowContainer'
  @Prop({ default: '8px' }) rowGap?: string
  @Prop({ default: '8px' }) columnGap?: string
}
