





































import { Component, Prop, Mixins } from 'vue-property-decorator'
import FormMixin from '@/amui/mixins/formMixin'
@Component({
  name: 'AmRateField',
})
export default class AmRateField extends Mixins(FormMixin) {
  static componentName = 'AmRateField'

  // @Prop(String) readonly value!: string
  @Prop({ default: 5 }) readonly max!: number
  @Prop({ default: false }) readonly allowHalf!: boolean
  @Prop({ default: false }) readonly showText!: boolean
  @Prop(Array) readonly texts!: string[]
  @Prop(Boolean) readonly showScore!: boolean
  @Prop(String) readonly textColor?: string
  @Prop({ default: false }) readonly colors!: string

  get label_i18n() {
    return this.label === '评分'
      ? (window as any).lowcode.i18n.get('apaas_amui_ratefield_label', '评分')
      : this.label
  }
}
