














import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  name: 'AmFrame',
})
export default class AmFrame extends Vue {
  static componentName = 'AmFrame'

  @Prop({
    default: 'about:blank',
  })
  url!: string

  @Prop() mode!: string
  @Prop({
    default: '100%',
  })
  width!: string
  @Prop({
    default: '100%',
  })
  height!: string
  @Prop({
    default: 'no',
  })
  scrolling!: string
  // @Prop({ default: 'horizontal' }) direction!: string
  // @Prop({ default: 0 }) active!: number
  // @Prop({ default: 'process' }) processStatus!: string
  // @Prop({ default: 'finish' }) finishStatus!: string
  // @Prop({ default: false }) alignCenter!: boolean
  // @Prop({ default: false }) simple!: boolean
  // @Prop({ default: () => [] }) options!: Array<any>
  // @Prop({ default: 1 }) type!: number

  get lineStyle() {
    return `height: ${this.height};width:${this.width};`
  }
  // get isBasic() {
  //   return this.type == 2
  // }
}
