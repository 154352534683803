
















































import { Component, Prop, Mixins } from 'vue-property-decorator'
import FormMixin from '@/amui-fe/mixins/formMixin'

@Component({
  name: 'AmFeCheckboxField',
})
export default class AmFeCheckboxField extends Mixins(FormMixin) {
  static componentName = 'AmFeCheckboxField'

  @Prop({ default: 'column' }) itemDirection!: string
  @Prop({ default: 'default' }) shape!: string
  @Prop({ default: false }) readonly border!: boolean
  @Prop({ default: () => [] }) options!: Array<any>
  get label_i18n() {
    return this.label === '多选'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_checkboxfield_label',
          '多选'
        )
      : this.label
  }
}
