






import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'ChildContainer',
})
export default class ChildContainer extends Vue {
  static componentName = 'ChildContainer'
}
