






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'SlotComponent',
})
export default class SlotComponent extends Vue {
  static componentName = 'SlotComponent'
  @Prop() name?: string
}
