











import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'Text',
})
export default class Text extends Vue {
  static componentName = 'Text'

  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get(
        'apaas_amui_text_content',
        '文本区'
      )
    },
  })
  content!: string
  @Prop({ default: 0 }) maxLine!: number
}
