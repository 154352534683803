





import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  name: 'AmBizVoice',
})
export default class AmBizVoice extends Vue {
  static componentName = 'AmBizVoice'
  //语音时长
  @Prop({ default: 0 }) second!: number
  //是否播放
  @Prop({ default: false }) isPlay!: boolean
  //语音文案
  @Prop() text!: string

  change() {
    this.$emit('change')
  }
}
