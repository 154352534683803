import { Vue, Component, Prop } from 'vue-property-decorator'
import '../assets/css/button.scss'
@Component({
  name: 'ButtonMixin',
})
export default class ButtonMixin extends Vue {
  @Prop() title?: string
  @Prop({ default: 'primary' }) type!: string
  @Prop({ default: 'medium' }) size!: string
  @Prop({ default: 'normal' }) behavivor!: string
  @Prop() icon?: string
  @Prop({ default: 'left' }) iconAlign!: string
  @Prop() borderType!: string
  @Prop({ default: false }) loading!: boolean
  @Prop({ default: false }) autofocus!: boolean

  get iconObj() {
    // 旧数据兼容（原为yxt-icon string类型）
    return this.icon
      ? typeof this.icon === 'string'
        ? {
            iconType: 'default',
            value: this.icon,
          }
        : {
            iconType: (this.icon as any).iconType || '',
            value: (this.icon as any).value || '',
          }
      : {
          iconType: 'default',
          value: '',
        }
  }

  get iconType() {
    return this.iconObj.iconType
  }
  get iconName() {
    return this.iconObj.value
  }

  get buttonClass() {
    let classes: string[] = []
    this.isHidden && classes.push('is-hidden')
    this.isDisabled && classes.push('is-disabled')
    return classes.join(' ')
  }

  get isPlain() {
    return this.borderType == 'plain'
  }
  get isRound() {
    return this.borderType == 'round'
  }
  get isCircle() {
    return this.borderType == 'circle'
  }
  get isDisabled() {
    return this.behavivor == 'disabled'
  }
  get isHidden() {
    return this.behavivor == 'hidden'
  }
  get buttonTitle() {
    let tit = this.title?.trim()
    return tit === '按钮'
      ? (window as any).lowcode.i18n.get('apaas_amui_button_label', '按钮')
      : tit
  }
}
