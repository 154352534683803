

















































import { Component, Prop, Mixins } from 'vue-property-decorator'
import formMixin from '@/amui/mixins/formMixin'
import amSelectFieldMixin from '@/amui/mixins/amSelectFieldMixin'

@Component({
  name: 'AmSelectField',
})
export default class AmSelectField extends Mixins(
  formMixin,
  amSelectFieldMixin
) {
  static componentName = 'AmSelectField'

  @Prop({ default: '请选择' }) readonly placeholder!: string

  get label_i18n() {
    return this.label === '单选下拉框'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_selectfield_label',
          '单选下拉框'
        )
      : this.label
  }
}
