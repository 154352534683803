












































import { Component, Prop, Mixins } from 'vue-property-decorator'
import FormMixin from '@/amui/mixins/formMixin'

@Component({
  name: 'AmCascaderField',
})
export default class AmCascaderField extends Mixins(FormMixin) {
  static componentName = 'AmCascaderField'

  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get(
        'apaas_amui_formfieldselect_placeholder',
        '请选择'
      )
    },
  })
  placeholder!: string
  @Prop(Number) panelWidth?: number
  @Prop({ default: 196 }) panelHeight!: number
  @Prop({ default: true }) showAllLevels!: boolean
  @Prop({ default: false }) collapseTags!: boolean
  @Prop({ default: false }) clearable!: boolean
  @Prop({ default: false }) filterable!: boolean
  @Prop({ default: '/' }) separator!: string
  @Prop({ default: 300 }) debounce!: number
  @Prop() filterMethod?: any
  @Prop() beforeFilter?: any
  @Prop({ default: () => [] }) options!: Array<any>
  @Prop() props!: Record<any, any>

  get label_i18n() {
    return this.label === '级联选择器'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_cascaderfield_label',
          '级联选择器'
        )
      : this.label
  }
}
