


















import { Component, Mixins } from 'vue-property-decorator'
import Mixin from './mixin'

@Component({
  name: 'AmFePopupContainerForEdit',
})
export default class AmFePopupContainerForEdit extends Mixins(Mixin) {
  static componentName = 'AmFePopupContainerForEdit'

  editPanelIsShowing = false
  get panelStyle() {
    return { width: this.width }
  }

  showEditPanel() {
    this.editPanelIsShowing = true
  }
  hideEditPanel() {
    this.editPanelIsShowing = false
  }
}
