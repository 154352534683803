import { Vue, Component, Prop } from 'vue-property-decorator'

const stateOrTagTypes = ['', 'success', 'warning', 'danger', 'info']
const stateOrTagTypeLength = stateOrTagTypes.length
const useContentTag = '{value}'

@Component({
  name: 'AmTableColumnTemplateMixin',
})
export default class AmTableColumnTemplateMixin extends Vue {
  @Prop(Object) readonly config!: any
  @Prop(Number) readonly index!: number
  @Prop(String) readonly content?: string

  get isTagType() {
    return this.config.type === 'tag'
  }

  get values() {
    return this.config.values.filter(value => !!value)
  }

  get stateOrTagTypes() {
    return !this.isTagType
      ? stateOrTagTypes
      : stateOrTagTypes.filter((_, index) => !!this.config.values[index])
  }

  get stateType() {
    return this.stateOrTagTypes[this.index % stateOrTagTypeLength]
  }

  get valueIndex() {
    return this.index % this.values.length
  }

  get valueContent() {
    const target = this.values[this.valueIndex]
    if (!this.isTagType) return target
    return target === useContentTag ? this.content : target
  }

  get tagType() {
    return this.stateOrTagTypes[this.valueIndex]
  }
}
