import { Vue, Component, Inject, Prop } from 'vue-property-decorator'
import '../assets/css/AmFeForm.scss'
@Component({
  name: 'FormMixin',
})
export default class FormMixin extends Vue {
  @Prop(String) readonly prop?: string
  @Prop(String) readonly label!: string
  @Prop({ default: 'left', type: String }) readonly position!: string
  @Prop({ default: 'left' }) readonly labelAlign!: string
  @Prop({ default: '100px' }) readonly labelWidth!: number
  @Prop(String) readonly tips?: string
  @Prop({ default: 'medium' }) readonly size!: string
  @Prop({ default: 'normal' }) readonly behavior!: string
  @Prop({ default: () => [] }) readonly validation!: Array<any>
  @Prop(String) readonly labelTooltip?: string
  @Prop({ default: 100 }) readonly labelTooltipWidth!: number
  @Prop({ default: 'dark' }) readonly tooltipEffect!: string
  @Prop({ default: true }) readonly showMessage!: boolean
  @Prop({ default: false }) readonly inlineMessage!: boolean
  @Prop() value!: any
  // 2023-9-26 该属性已废弃，请使用 amRootVm，保留仅做兼容用途
  @Prop({ type: Object, default: () => {} }) readonly caller!: Vue

  @Inject('amRootVm') readonly amRootVm!: Vue
  get rootVm() {
    const _rootVM: any = this.amRootVm || this.caller || {}
    if (!_rootVM.global) _rootVM.global = {}
    return _rootVM
  }

  get activeItem() {
    return this.$store.getters.activeItem
  }

  get width() {
    return this.label ? this.labelWidth || 'auto' : '0px'
  }
  //组件布局
  get displayClass() {
    let classes = [
      `layout-${this.position}`,
      this.position == 'top' ? 'left' : this.labelAlign,
    ]
    this.hidden && classes.push('is-hidden')
    this.readonly && classes.push('is-readonly')
    return classes
  }

  get readonly() {
    return this.behavior == 'readonly'
  }

  get disabled() {
    return this.behavior == 'disabled'
  }

  get hidden() {
    return this.behavior == 'hidden'
  }

  change(val) {
    this.$emit('change', val)
  }

  get rules() {
    let rules: any = []
    const funObjs = {
      required: item => {
        rules.push({
          required: true,
          message: item.message,
          trigger: ['blur', 'change'],
        })
      },
      pattern: item => {
        rules.push({
          required: true,
          pattern: eval(item.value),
          message: item.message,
          trigger: item.trigger,
        })
      },
      validator: item => {
        rules.push({
          validator: new Function('return ' + item.value).call(this.rootVm),
          trigger: item.trigger,
        })
      },
    }
    this.validation.forEach(v => {
      // isApply 在画布中时必然存在
      // 在预览和产物中时会被移除
      // 因此这里需要判断 === false
      if (v.isApply === false) return
      funObjs[v.type]?.(v)
    })
    return rules
  }
}
