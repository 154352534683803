






























































import { Component, Prop, Mixins } from 'vue-property-decorator'
import FormMixin from '@/amui/mixins/formMixin'
@Component({
  name: 'AmBizCheckSinglePosition',
})
export default class AmBizCheckSinglePosition extends Mixins(FormMixin) {
  static componentName = 'AmBizCheckSinglePosition'
  visible = false

  //选择形式
  @Prop({ default: 'button' }) selectType!: string
  //按钮文案
  @Prop({ default: '选择岗位' }) buttonText!: string
  //按钮类型
  @Prop({ default: 'primary' }) buttonType!: string
  @Prop({ default: 'medium' }) readonly buttonSize!: string
  //选择的岗位
  @Prop({ type: Array, default: [] }) checkedPositions!: any
  //是否多选
  @Prop({ default: false }) multiple!: boolean
  //是否是集团版
  @Prop({ default: false }) enableGroupCorp!: boolean

  open() {
    this.visible = true
  }

  cancel() {
    this.visible = false
    this.checkedPositions = []
    this.$emit('cancel')
  }

  confirm() {
    console.log(this.checkedPositions, '选择岗位数据')
    this.$emit('input', this.checkedPositions)
    this.$emit('confirm', this.checkedPositions)
    this.visible = false
  }
}
