import { Vue, Component, Inject, Prop } from 'vue-property-decorator'

@Component({
  name: 'TableBaseMixin',
})
export default class TableBaseMixin extends Vue {
  // 2023-9-26 该属性已废弃，请使用 amRootVm，保留仅做兼容用途
  @Prop({ type: Object, default: () => {} }) readonly caller!: Vue

  //是否启用读取页面级实例
  @Prop(Boolean) readonly useDeepRootVM?: boolean

  @Inject('amRootVm') readonly amRootVm!: Vue
  get rootVm() {
    if (this.useDeepRootVM) return this.deepRootVm
    return this.currentRootVm
  }

  get currentRootVm() {
    let _rootVM: any = this.amRootVm || this.caller || {}
    if (!_rootVM.global) _rootVM.global = {}
    return _rootVM
  }

  get deepRootVm() {
    let _rootVM: any = this
    while (_rootVM.amRootVm) {
      _rootVM = _rootVM.amRootVm
    }
    if (!_rootVM.global) _rootVM.global = {}
    return _rootVM
  }

  getRootVm(func) {
    let _rootVm: any = this
    while (!_rootVm[func] && !!_rootVm.amRootVm) {
      _rootVm = _rootVm.amRootVm
    }
    return _rootVm
  }
}
