import Vue from 'vue'
const isServer = Vue.prototype.$isServer
const SPECIAL_CHARS_REGEXP = /([\:\-\_]+(.))/g
const MOZ_HACK_REGEXP = /^moz([A-Z])/

const ieVersion = isServer ? 0 : Number(document['documentMode'])

export function setupAppMakerGlobalVariable() {
  if (!window['appMaker']) window['appMaker'] = {}
  return window['appMaker']
}

export function getAppMakerGlobalVariable(field: string) {
  return setupAppMakerGlobalVariable()[field]
}

export function setAppMakerGlobalVariable(key: string, value: any) {
  setupAppMakerGlobalVariable()[key] = value
}

export function platformIsMobile() {
  return getAppMakerGlobalVariable('platform') === 'mobile'
}
/**
 * 根据当前页面地址获取页面信息，如：应用预览页/#/preview/815/4505  返回{'name':'preview','path':'/preview/818/4505'}
 */
export function getAppPageInfo(): { name: string; path: string } {
  let res = { name: '', path: '' }
  let map: Record<string, { regexp: RegExp; name: string }> = {
    preview: {
      name: 'preview',
      regexp: /\/preview\/(\d+)\/\d+/gi,
    },
    app: {
      name: 'app',
      regexp: /\/app\/(\d+)(\/\d+)?/gi,
    },
    editor: {
      name: 'editor',
      regexp: /\/editor\/page\/(\d+)\/\d+/gi,
    },
  }
  for (let item in map) {
    let match = map[item].regexp.exec(location.href)
    if (match) {
      res = { ...map[item], path: match[0] }
      break
    }
  }
  return res
}

export const isScroll = (el, vertical) => {
  if (isServer) return

  const determinedDirection = vertical !== null || vertical !== undefined
  const overflow = determinedDirection
    ? vertical
      ? getStyle(el, 'overflow-y')
      : getStyle(el, 'overflow-x')
    : getStyle(el, 'overflow')

  return overflow ? overflow.match(/(scroll|auto)/) : false
}

const camelCase = function (name) {
  return name
    .replace(SPECIAL_CHARS_REGEXP, function (_, separator, letter, offset) {
      return offset ? letter.toUpperCase() : letter
    })
    .replace(MOZ_HACK_REGEXP, 'Moz$1')
}

export const getStyle =
  ieVersion < 9
    ? function (element, styleName) {
        if (isServer) return
        if (!element || !styleName) return null
        styleName = camelCase(styleName)
        if (styleName === 'float') {
          styleName = 'styleFloat'
        }
        try {
          switch (styleName) {
            case 'opacity':
              try {
                return element.filters.item('alpha').opacity / 100
              } catch (e) {
                return 1.0
              }
            default:
              return element.style[styleName] || element.currentStyle
                ? element.currentStyle[styleName]
                : null
          }
        } catch (e) {
          return element.style[styleName]
        }
      }
    : function (element, styleName) {
        if (isServer) return
        if (!element || !styleName) return null
        styleName = camelCase(styleName)
        if (styleName === 'float') {
          styleName = 'cssFloat'
        }
        try {
          const computed = document.defaultView?.getComputedStyle(element, '')
          if (computed) {
            return computed[styleName]
          } else {
            return element.style[styleName]
          }
        } catch (e) {
          return element.style[styleName]
        }
      }

export const getScrollContainer = (el, vertical) => {
  if (isServer) return

  let parent = el
  while (parent) {
    if ([window, document, document.documentElement].includes(parent)) {
      return window
    }
    if (isScroll(parent, vertical)) {
      return parent
    }
    parent = parent.parentNode
  }

  return parent
}
