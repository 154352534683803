



































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'AmCheckboxGroup',
})
export default class AmCheckboxGroup extends Vue {
  static componentName = 'AmCheckboxGroup'
  //选项值集合
  @Prop({ default: () => [] }) readonly options!: Array<any>
  //展现形式
  @Prop({ default: 'default' }) shape!: string
  //绑定值
  @Prop() readonly value!: any
  //尺寸
  @Prop() readonly size!: string
  //是否禁用
  @Prop({ default: false }) readonly disabled!: boolean
  //可被勾选的 checkbox 的最小数量
  @Prop() readonly min!: number
  //可被勾选的 checkbox 的最大数量
  @Prop() readonly max!: number
  //按钮形式的 Checkbox 激活时的文本颜色
  @Prop() readonly textColor!: string
  //按钮形式的 Checkbox 激活时的填充色和边框色
  @Prop() readonly fill!: string
  //排布方式
  @Prop({ default: 'column' }) readonly direction!: string
  //是否显示边框
  @Prop({ default: false }) readonly border!: boolean
  //设置半选，全选状态，只负责样式控制
  @Prop({ default: false }) readonly indeterminate!: boolean
  //checkbox 的 label 是否根据选中状态改变
  @Prop({ default: false }) readonly changeTextColor!: boolean | number
}
