






import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'SplitColumn',
})
export default class SplitColumn extends Vue {
  static componentName = 'SplitColumn'
}
