
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { throttle } from 'lodash'

type SplitMode = 'left' | 'right' | 'top' | 'bottom'
const COLUMN_FIXED_MODES: SplitMode[] = ['top', 'bottom']
const LEADING_FIXED_MODES: SplitMode[] = ['top', 'left']
// 拖拽调整分栏大小时，每个分栏的最小值
const RESIZING_SPLITE_ITEM_MIN_SIZE = 10

@Component({
  name: 'SplitLayout',
})
export default class SplitLayout extends Vue {
  static componentName = 'SplitLayout'

  @Prop({ type: String, default: 'left' }) readonly splitMode!: SplitMode
  @Prop({ type: String, default: '16px' }) readonly gap!: string
  @Prop({ type: String, default: '100px' }) readonly fixedSize!: string
  @Prop({ type: Boolean, default: false }) readonly resizable!: boolean
  // 是否强制禁用拖拽交互
  // 这个属性只用于 AM 编辑模式下禁用交互
  // 拖拽手柄是否可见任然取决于 resizable
  @Prop({ type: Boolean, default: false }) readonly forceDisableResize!: boolean

  // 边栏大小，是一个 css 字符串，可能是 px 值也可能是 calc 计算值
  siderSize = ''
  // 当前是否调整大小中
  isResizing = false
  // 拖拽手柄在拖拽过程中每一次的偏移量
  dragHandlerLastOffset = 0
  // 拖拽手柄的 css transform 的值
  dragHandlerTransform = 0

  // 是否列布局模式，即上下布局
  get isColumnMode() {
    return COLUMN_FIXED_MODES.includes(this.splitMode)
  }
  // 是否固定左侧/上方
  get isFixedLeading() {
    return LEADING_FIXED_MODES.includes(this.splitMode)
  }
  // 添加在容器上的额外属性
  get splitLayoutExtraClassNames() {
    let classNames: string[] = []
    if (this.isColumnMode) classNames.push('split-column')

    return classNames
  }
  // 是否启用调整大小的动能
  get enabledResize() {
    return !this.forceDisableResize && this.resizable
  }
  // 获取分栏大小的 key
  get splitItemSizeKey() {
    return this.isColumnMode ? 'clientHeight' : 'clientWidth'
  }
  // 拖拽手柄样式
  get handlerStyle() {
    let positionKey = ''
    let transformKey = ''
    if (this.isColumnMode) {
      positionKey = this.isFixedLeading ? 'top' : 'bottom'
      transformKey = 'translateY'
    } else {
      positionKey = this.isFixedLeading ? 'left' : 'right'
      transformKey = 'translateX'
    }

    return `
      ${positionKey}: ${this.fixedSize};
      transform: ${transformKey}(${this.dragHandlerTransform}px);
    `
  }

  @Watch('splitMode')
  @Watch('gap', { immediate: true })
  async handleGapChange() {
    await this.$nextTick()
    const $splitLayout = this.$refs.$splitLayout as HTMLDivElement
    if (!$splitLayout) return
    const $firstFlexItem = $splitLayout.firstElementChild as HTMLDivElement
    if (!$firstFlexItem) return

    const gap = this.gap || '16px'
    if (this.isColumnMode) {
      $firstFlexItem.style.marginBottom = gap
      $firstFlexItem.style.marginRight = ''
    } else {
      $firstFlexItem.style.marginBottom = ''
      $firstFlexItem.style.marginRight = gap
    }
  }

  @Watch('splitMode')
  @Watch('fixedSize', { immediate: true })
  handleFixedSizeChange() {
    this.siderSize = this.fixedSize
    this.dragHandlerTransform = 0
  }

  @Watch('splitMode')
  @Watch('siderSize', { immediate: true })
  async handleSiderSizeChange() {
    await this.$nextTick()
    const $splitLayout = this.$refs.$splitLayout as HTMLDivElement
    if (!$splitLayout) return

    const $fixedFlexItem = (
      this.isFixedLeading
        ? $splitLayout.firstElementChild
        : $splitLayout.children[1]
    ) as HTMLDivElement
    if (!$fixedFlexItem) return

    const $expandFlexItem = (
      this.isFixedLeading
        ? $splitLayout.children[1]
        : $splitLayout.firstElementChild
    ) as HTMLDivElement
    if (!$expandFlexItem) return

    // 异常情况，基本不会出现
    // 暂不处理
    if ($fixedFlexItem === $expandFlexItem) return

    $fixedFlexItem.style.flexGrow = '0'
    $fixedFlexItem.style.flexShrink = '0'
    $expandFlexItem.style.flexGrow = '1'
    $expandFlexItem.style.flexShrink = '0'

    if (this.isColumnMode) {
      $fixedFlexItem.style.height = this.siderSize
      $fixedFlexItem.style.width = ''
      $expandFlexItem.style.height = '0px'
      $expandFlexItem.style.width = ''
    } else {
      $fixedFlexItem.style.height = ''
      $fixedFlexItem.style.width = this.siderSize
      $expandFlexItem.style.height = ''
      $expandFlexItem.style.width = '0px'
    }
  }

  throttledMouseMove = throttle(function (e: MouseEvent) {
    const currentOffset = this.isColumnMode ? e.pageY : e.pageX
    // 本次 mousemove 与上次 mousemove 之间的差值
    // 小于 0 表示左侧/上方正在缩小
    // 大于 0 表示右侧/下方正在缩小
    const deltaOffset = currentOffset - this.dragHandlerLastOffset

    const $splitLayout = this.$refs.$splitLayout as HTMLDivElement
    // 获取正在缩小的分栏的 size
    // 如果正在缩小的分栏 size <= RESIZING_SPLITE_ITEM_MIN_SIZE 则禁止更新视图
    const $shrinkingSplitItem =
      deltaOffset < 0 ? $splitLayout.children[0] : $splitLayout.children[1]
    const shrinkingSplitItemSize = $shrinkingSplitItem[this.splitItemSizeKey]
    if (shrinkingSplitItemSize <= RESIZING_SPLITE_ITEM_MIN_SIZE) return

    // 计算更新视图需要的值
    this.dragHandlerTransform = deltaOffset + this.dragHandlerTransform
    this.siderSize = `calc(${this.fixedSize} ${
      this.isFixedLeading ? '+' : '-'
    } ${this.dragHandlerTransform}px)`

    // 将本次的偏移量赋值给 dragHandlerLastOffset
    // 便于下次计算
    this.dragHandlerLastOffset = currentOffset
  }, 32)

  handleMouseDown(e: MouseEvent) {
    if (!this.enabledResize) return
    this.isResizing = true
    this.dragHandlerLastOffset = this.isColumnMode ? e.pageY : e.pageX
  }

  handleMouseUp() {
    if (!this.enabledResize) return
    this.isResizing = false
  }

  handleMouseMove(e: MouseEvent) {
    if (!this.enabledResize || !this.isResizing) return
    this.throttledMouseMove(e)
  }
}
