
























import { Component, Mixins } from 'vue-property-decorator'
import amTableColumnTemplateMixin from '../../amui/mixins/amTableColumnTemplateMixin'

@Component({
  name: 'AmFeTableColumnTemplate',
})
export default class extends Mixins(amTableColumnTemplateMixin) {
  static componentName = 'AmFeTableColumnTemplate'
}
