


















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'AmAnchor',
})
export default class AmAnchor extends Vue {
  static componentName = 'AmAnchor'
  @Prop({ default: [] }) readonly data?: any
  @Prop({ default: 'default' }) readonly type?: string
  @Prop({ default: 'vertical' }) readonly direction?: string
  @Prop() readonly container?: any
  @Prop({ default: 0 }) readonly offset?: number
  @Prop({ default: true }) marker?: boolean
}
