








































































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import FormMixin from '@/amui/mixins/formMixin'
@Component({
  name: 'AmBizSelectField',
})
export default class AmBizSelectField extends Mixins(FormMixin) {
  static componentName = 'AmBizSelectField'
  visible = false
  tabs: any[] = []
  options = {}
  title = ''

  list: any[] = []
  activeTab = 0

  @Prop({ default: '' }) selectType!: string
  @Prop({ default: () => [] }) value!: Array<any>
  @Prop({ default: true }) readonly multiple!: boolean
  @Prop({ default: 0 }) readonly childrenIncluded!: number
  @Prop({ default: 0 }) readonly limit!: number
  @Prop({ default: true }) readonly count!: boolean
  // 讲师 - 类型
  @Prop({ default: -1 }) readonly type!: number
  // 讲师 - 是否多选
  @Prop({ default: true }) readonly singleMode!: boolean
  // 讲师 - 预备讲师
  @Prop({ default: true }) readonly prepared!: boolean
  //导航功能权限code
  @Prop({ default: '' }) functionCode!: string
  //数据权限code
  @Prop({ default: '' }) dataPermissionCode!: string

  created() {
    this.initData()
  }

  @Watch('selectType')
  initData() {
    switch (this.selectType) {
      case 'dept':
        this.tabs = ['department']
        this.title = (this as any).lowcode.i18n.get(
          'apaas_amui_deptfield_label',
          '选择部门'
        )

        this.options = {
          department: {
            childrenIncluded: this.childrenIncluded == 0 ? false : true,
            count: this.count,
          },
        }
        break
      case 'position':
        this.tabs = ['position']
        this.title = (window as any).lowcode.i18n.get(
          'apaas_amui_positionfield_label',
          '选择岗位'
        )
        break
      case 'teacher':
        this.tabs = [
          { label: ' ', name: ' ' },
          { label: '讲师', name: 'teacherTab' },
        ]
        this.title = (window as any).lowcode.i18n.get(
          'apaas_amui_teacherfield_label',
          '选择讲师'
        )
        this.activeTab = 1
        break
      case 'user':
        this.tabs = ['department', 'person', 'manage']
        this.options = {
          department: {
            count: this.count,
          },
          person: {
            count: this.count,
          },
        }
        break
      case 'userGroup':
        this.tabs = ['usergroup']
        this.title = (window as any).lowcode.i18n.get(
          'apaas_amui_usergroupfield_label',
          '选择用户组'
        )
        break
      default:
        this.tabs = []
        break
    }
  }

  open() {
    this.list = JSON.parse(JSON.stringify(this.value))
    this.visible = true
  }

  confirm() {
    const count = this.list.length
    if (this.selectType == 'teacher') {
      if ((this.singleMode && count <= 1) || !this.singleMode) {
        this.update()
      } else {
        this.$message.warning(`只能选1条`)
      }
    } else {
      if (this.multiple) {
        if (this.limit == 0 || this.limit >= count) {
          this.update()
        } else {
          this.$message.warning(`最多只能选${this.limit}条`)
        }
      } else {
        if (count <= 1) {
          this.update()
        } else {
          this.$message.warning('只能选1条')
        }
      }
    }
  }

  update() {
    // 数组数据更新响应式
    this.value.splice(0, this.value.length, ...this.list)
    this.$emit('change', this.value)
    this.visible = false
  }
}
