
import { Component, Prop, Mixins } from 'vue-property-decorator'
import AmBizSelectField from './AmBizSelectField.vue'
@Component({
  name: 'AmUserField',
})
export default class AmUserField extends Mixins(AmBizSelectField) {
  static componentName = 'AmUserField'

  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get(
        'apaas_amui_userfield_placeholder',
        '请选择用户'
      )
    },
  })
  placeholder!: string

  get label_i18n() {
    return this.label === '选择用户'
      ? (window as any).lowcode.i18n.get(
          'apaas_amui_userfield_label',
          '选择用户'
        )
      : this.label
  }
}
