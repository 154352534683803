

















































import { Component, Mixins, Prop } from 'vue-property-decorator'
import FormMixin from '@/amui-fe/mixins/formMixin'

@Component({
  name: 'AmFeRadioField',
})
export default class AmFeRadioField extends Mixins(FormMixin) {
  static componentName = 'AmFeRadioField'
  ruleForm = {
    value: '',
  }

  // @Prop([String, Number, Boolean]) readonly value!: string | number | boolean
  @Prop([String, Number, Boolean]) readonly options!: string | number | boolean
  @Prop({ default: 'default' }) readonly shape!: string
  @Prop({ default: 'column' }) readonly itemDirection!: string
  @Prop({ default: false }) readonly border!: boolean

  get label_i18n() {
    return this.label === '单选'
      ? (window as any).lowcode.i18n.get('apaas_amui_radiofield_label', '单选')
      : this.label
  }
}
