














import { Component, Mixins } from 'vue-property-decorator'
import LinkMixin from '@/amui/mixins/linkMixin'

@Component({
  name: 'AmLinkContainer',
})
export default class AmLinkContainer extends Mixins(LinkMixin) {
  static componentName = 'AmLinkContainer'
}
