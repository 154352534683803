















import { Component, Mixins } from 'vue-property-decorator'
import AmImageMixin from '@/amui/mixins/AmImageMixin'

@Component({
  name: 'AmImage',
})
export default class AmImage extends Mixins(AmImageMixin) {
  static componentName = 'AmImage'
}
