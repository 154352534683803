















































import { Component, Mixins } from 'vue-property-decorator'
import AmDropdownMenuMixin from './mixin'

@Component({
  name: 'AmDropdownMenu',
})
export default class AmDropdownMenu extends Mixins(AmDropdownMenuMixin) {
  static componentName = 'AmDropdownMenu'
}
