






import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
@Component({
  name: 'AmHtml',
})
export default class AmStep extends Vue {
  static componentName = 'AmHtml'

  @Prop({
    default: '',
  })
  html!: string

  @Watch('html')
  handleHTMLChange() {
    const html = this.$refs.refHTML as HTMLElement
    if (html) {
      try {
        html.scrollTop = 0
        html.scrollTo(0, 0)
      } catch (err) {}
    } else {
      console.log('找不到html')
    }
  }

  @Prop({
    default: '100%',
  })
  width!: string
  @Prop({
    default: '100%',
  })
  height!: string

  @Prop({
    default: false,
  })
  autoHeight!: boolean

  @Prop({
    default: 'no',
  })
  scrolling!: string

  get lineStyle() {
    if (this.autoHeight) {
      return `height: auto;width:${this.width};`
    }
    return `height: ${this.height};width:${this.width};`
  }
  // get isBasic() {
  //   return this.type == 2
  // }
}
