import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import '../assets/css/icon.scss'
@Component({
  name: 'IconMixin',
})
export default class IconMixin extends Vue {
  @Prop({ default: 'platform-eleme' }) name!: string
  @Prop({ default: 'default' }) iconType!: string
  @Prop({ default: '24px' }) size!: string
  @Prop() color?: string

  iconClass: string = ''
  remoteUrl: string = ''

  @Watch('name', { immediate: true })
  changeName(v) {
    if (
      this.iconType == 'custom' &&
      (v.includes('remoteUrl') || v.startsWith('http'))
    ) {
      // 兼容先前
      if (v.includes('remoteUrl')) {
        let { iconClass = '', remoteUrl = '' } = JSON.parse(v)
        this.iconClass = iconClass
        this.remoteUrl = remoteUrl
      } else {
        let index = v.lastIndexOf('/')
        this.remoteUrl = v.substr(0, index)
        this.iconClass = v.substr(index + 1).split('.')[0]
      }
    } else {
      this.iconClass = v
      this.remoteUrl = ''
    }

    if (this.remoteUrl && this['lowcode']?.utils?.url2cdn)
      this.remoteUrl = this['lowcode'].utils.url2cdn(this.remoteUrl)
  }

  get style() {
    return {
      color: this.color,
      fontSize: this.size,
    }
  }
}
