

















































































import { Component, Mixins } from 'vue-property-decorator'
import AmDropdownMenuMixinV2 from './mixin'

@Component({
  name: 'AmDropdownMenuV2',
})
export default class AmDropdownMenuV2 extends Mixins(AmDropdownMenuMixinV2) {
  static componentName = 'AmDropdownMenuV2'
}
