
import { Component, Mixins } from 'vue-property-decorator'
import CellContainer from '../../amui/components/CellContainer.vue'
@Component({
  name: 'AmFeCellContainer',
})
export default class AmFeCellContainer extends Mixins(CellContainer) {
  static componentName = 'AmFeCellContainer'

  parentType: '' | 'AmFeRowContainer' | 'AmFeColContainer' = ''

  get isCol() {
    return this.parentType === 'AmFeColContainer'
  }

  get isRow() {
    return this.parentType === 'AmFeRowContainer'
  }

  get style() {
    let parent = this.$parent
    while (parent) {
      let name = (parent as any).compName

      if (name === 'AmFeRowContainer' || name === 'AmFeColContainer') {
        this.parentType = name
        break
      }

      parent = parent.$parent
    }

    let styleflexBasis: {
      flexGrow: number
      flexShrink: number
      [properyName: string]: string | number
    } = {
      flexGrow: 0,
      flexShrink: 0,
    }

    let size = this.isRow ? this.$el?.clientWidth : this.$el?.clientHeight

    if (this.flexBasis === 'fixed') {
      styleflexBasis.flexBasis = `${size}px`
    }

    if (this.width) {
      let width =
        typeof this.width === 'number' ? this.width + 'px' : this.width
      styleflexBasis.flexBasis = width
    }

    if (this.flexBasis === 'fixed') {
      return styleflexBasis
    } else {
      return {}
    }
  }
}
