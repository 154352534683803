






















import { Component, Mixins, Prop } from 'vue-property-decorator'
import IconMixin from '@/amui/mixins/iconMixin'

@Component({
  name: 'AmFeIcon',
})
export default class AmFeIcon extends Mixins(IconMixin) {
  static componentName = 'AmFeIcon'
  //是否有旋转动画
  @Prop({ default: false }) spin?: boolean
}
