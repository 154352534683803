










































































































import { Component, Mixins } from 'vue-property-decorator'
import amTableMixin from '../../amui/mixins/amTableMixin'
import AmFeTableColumn from './AmFeTableColumn.vue'

@Component({
  name: 'AmFeTable',
  components: { AmFeTableColumn },
})
export default class extends Mixins(amTableMixin) {
  static componentName = 'AmFeTable'
}
