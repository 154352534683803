















































import { Component, Mixins } from 'vue-property-decorator'
import AmDropdownMenuMixin from './mixin'

@Component({
  name: 'AmFeDropdownMenu',
})
export default class AmFeDropdownMenu extends Mixins(AmDropdownMenuMixin) {
  static componentName = 'AmFeDropdownMenu'
}
