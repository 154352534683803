





import { Component, Prop, Vue } from 'vue-property-decorator'
import { platformIsMobile } from '../util'

const PADDING_NONE = ['0px', '0%', '0em']

@Component({
  name: 'Page',
})
export default class Page extends Vue {
  static componentName = 'Page'

  @Prop(String) readonly width?: string
  @Prop({ default: '16px' }) readonly padding?: string
  @Prop(String) readonly mobilePadding?: string

  get extraStyles() {
    let styles: Record<string, string> = {}
    const isMobile = platformIsMobile()
    if (isMobile) {
      if (this.mobilePadding && !PADDING_NONE.includes(this.mobilePadding))
        styles.padding = this.mobilePadding
    } else {
      if (this.padding && !PADDING_NONE.includes(this.padding))
        styles.padding = this.padding
      if (this.width) {
        styles.width = this.width
        styles.margin = 'auto'
      }
    }
    return styles
  }
}
