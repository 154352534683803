import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

export type ButtonState = 'normal' | 'disabled' | 'loading'
export type FooterAlign = 'left' | 'center' | 'right'

@Component({
  name: 'ModalMixin',
})
export default class extends Vue {
  @Prop({ default: true }) readonly initVisible!: boolean
  @Prop({ default: '50%' }) readonly width!: string
  @Prop({ default: true }) readonly cutline!: boolean
  @Prop({ default: true }) readonly modal!: boolean
  @Prop({ default: '24px' }) readonly bodyPadding!: string
  @Prop({ default: () => [] }) readonly closeTypes!: string[]
  @Prop({ default: true }) readonly footer!: boolean | string
  @Prop({ default: true }) readonly headerTitle!: boolean | string
  @Prop({ default: 'right' }) readonly footerAlign!: FooterAlign
  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get(
        'apaas_amui_dialog_cancel',
        '取消'
      )
    },
  })
  readonly cancelText!: string
  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get(
        'apaas_amui_dialog_confirm',
        '确定'
      )
    },
  })
  readonly confirmText!: string
  @Prop({ default: 'normal' }) readonly confirmState!: ButtonState
  // enableClose = true closeTypes 才会生效
  @Prop({ type: Boolean, default: true }) readonly enableClose?: boolean
  @Prop({ type: Boolean, default: true }) readonly showClose?: boolean

  visible: boolean = false

  private get isAppMaker() {
    return (window as any).lowcode.__IS_APP_MAKER__ as boolean
  }

  get appendToBody() {
    return !this.isAppMaker
  }

  get bodyStyle() {
    if (!this.bodyPadding) return {}
    return {
      padding: this.bodyPadding,
    }
  }

  get closeOnClickModal() {
    return this.closeTypes.includes('close-on-click-modal')
  }

  get closeOnPressEscape() {
    return this.closeTypes.includes('close-on-press-escape')
  }

  get isCustomFooter() {
    return this.footer === 'custom'
  }

  get footerEnabled() {
    return this.footer === true && !!(this.cancelText || this.confirmText)
  }

  get isCustomTitle() {
    return this.headerTitle === 'custom'
  }

  get titleEnabled() {
    return this.headerTitle === true || this.headerTitle === 'custom'
  }

  get confirmLoading() {
    return this.confirmState === 'loading'
  }

  get confirmDisabled() {
    return this.confirmState === 'disabled'
  }

  @Watch('visible', { immediate: true })
  handleVisibleChange() {
    if (!this.enableClose) {
      this.visible = true
      return
    }

    if (this.visible) document.addEventListener('keydown', this.handleKeydown)
    else document.removeEventListener('keydown', this.handleKeydown)
  }

  show() {
    this.visible = true
  }

  close() {
    this.visible = false
  }

  cancelModal() {
    if (!this.enableClose) return
    if (this.$listeners.cancel) this.$emit('cancel', () => this.close())
    else this.close()
  }

  confirmModal() {
    if (!this.enableClose) return
    if (this.$listeners.confirm) this.$emit('confirm', () => this.close())
    else this.close()
  }

  /**
   * 处理 esc 关闭弹窗事件
   * yxt-drawer 的 esc 关闭存在 bug
   */
  handleKeydown(e: KeyboardEvent) {
    if (!this.closeOnPressEscape || !this.visible || e.key !== 'Escape') return
    e.stopPropagation()
    this.close()
  }

  created() {
    this.visible = this.initVisible
  }
}
