






import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'AmPopupContainerItem',
})
export default class AmContainer extends Vue {
  static componentName = 'AmPopupContainerItem'
}
